import { Link } from "react-router-dom";
import PageBreadcrumb from "../../../layouts/components/page-breadcrumb";
import { useState } from "react";
import ApiService from "../../../../app/services/api";

const ProjectsNew = () => {
  const [responseData, setResponseData] = useState(null);
  const [formData, setFormData] = useState({
    project_name: "",
    blockchain: "",
    github: "",
    contract_address: "",
    notes: "",
  });

  const handleSubmit = async () => {
    try {
      const response = await ApiService.post("projects/new", {
        formData: formData,
      });

      const responseData = await response.json();

      setResponseData(responseData);

      // if (responseData.status === "error") {
      //   throw new Error(response.message);
      // }

      // window.$.toast({
      //   heading: "Success",
      //   text: "Project submit success",
      //   position: "top-right",
      //   icon: "success",
      // });
    } catch (err) {
      window.$.toast({
        heading: "Error",
        text: "Project submit failed",
        position: "top-right",
        icon: "error",
      });
    }
  };

  return (
    <>
      <PageBreadcrumb title="New Project" />

      <div className="row">
        <div className="col-12">
          <div className="card">
            <div className="card-body">
              <h2>Want a project listed here?</h2>
              {responseData && responseData.status === "error" ? (
                <div className="alert alert-danger" role="alert">
                  {responseData.message}
                </div>
              ) : null}
              {responseData && responseData.status === "ok" ? (
                <div className="alert alert-success" role="alert">
                  Project submitted successfully.
                </div>
              ) : (
                <>
                  <form className="form-horizontal" role="form">
                    <div className="mb-2 row">
                      <label className="col-md-2 col-form-label">
                        Project Name
                      </label>
                      <div className="col-md-10">
                        <input
                          type="text"
                          className="form-control"
                          required
                          value={formData.project_name}
                          onChange={(e) => {
                            setFormData((prevFormData) => ({
                              ...prevFormData,
                              project_name: e.target.value,
                            }));
                          }}
                        />
                      </div>
                    </div>

                    <div className="mb-2 row">
                      <label className="col-md-2 col-form-label">
                        Blockchain (Testnet accepted)
                      </label>
                      <div className="col-md-10">
                        <input
                          type="text"
                          className="form-control"
                          required
                          value={formData.blockchain}
                          onChange={(e) => {
                            setFormData((prevFormData) => ({
                              ...prevFormData,
                              blockchain: e.target.value,
                            }));
                          }}
                        />
                      </div>
                    </div>

                    <div className="mb-2 row">
                      <label className="col-md-2 col-form-label">
                        Github (if public)
                      </label>
                      <div className="col-md-10">
                        <input
                          type="text"
                          className="form-control"
                          required
                          value={formData.github}
                          onChange={(e) => {
                            setFormData((prevFormData) => ({
                              ...prevFormData,
                              github: e.target.value,
                            }));
                          }}
                        />
                      </div>
                    </div>

                    <div className="mb-2 row">
                      <label className="col-md-2 col-form-label">
                        Contract Address
                      </label>
                      <div className="col-md-10">
                        <input
                          type="text"
                          className="form-control"
                          required
                          value={formData.contract_address}
                          onChange={(e) => {
                            setFormData((prevFormData) => ({
                              ...prevFormData,
                              contract_address: e.target.value,
                            }));
                          }}
                        />
                      </div>
                    </div>

                    <div className="mb-2 row">
                      <label className="col-md-2 col-form-label">
                        Notes (Include contact details - Discord/Telegram)
                      </label>
                      <div className="col-md-10">
                        <textarea
                          className="form-control"
                          rows={5}
                          required
                          value={formData.notes}
                          onChange={(e) => {
                            setFormData((prevFormData) => ({
                              ...prevFormData,
                              notes: e.target.value,
                            }));
                          }}
                        />
                      </div>
                    </div>
                    <div className="mb-2 row">
                      <div className="col-md-10 offset-2">
                        <button
                          type="button"
                          className="btn btn-primary waves-effect waves-light me-1"
                          onClick={handleSubmit}
                        >
                          Submit
                        </button>
                      </div>
                    </div>
                  </form>
                </>
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ProjectsNew;
