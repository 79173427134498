import { Outlet } from "react-router-dom";
import Topbar from "./components/topbar";
import Topnav from "./components/topnav";

import { BreadcrumbsItem } from "react-breadcrumbs-dynamic";

const DefaultLayout = () => {
  return (
    <>
      <BreadcrumbsItem to="/">Home</BreadcrumbsItem>

      <div id="wrapper">
        <Topbar />
        <Topnav />
        <div className="content-page">
          <div className="content">
            <div className="container-fluid">
              <Outlet />
            </div>
          </div>
        </div>
      </div>
      <button
        className="btn btn-sm btn-success floating-feedback-button"
        onClick={() => {
          // URL of the form
          var url =
            "https://docs.google.com/forms/d/e/1FAIpQLSe9BAQZK70hAerXmQiRJKIOrhqpxccRvrUy2jCcHuDtpBI9QA/viewform?usp=sf_link";

          // Open a new window with the specified URL
          window.open(url, "_blank");
        }}
      >
        Feedback
      </button>
    </>
  );
};

export default DefaultLayout;
