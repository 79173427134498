import { forwardRef, useEffect, useImperativeHandle, useState } from "react";
import ReactJson from "react-json-view";

const DashboardConfig = forwardRef((props, ref) => {
  const { worldContract } = props;
  const [formData, setFormData] = useState({
    config: {},
  });

  useImperativeHandle(ref, () => ({
    getFormData: () => formData,
  }));

  useEffect(() => {
    if (!worldContract) return;

    setFormData((prevFormData) => ({
      ...prevFormData,
      config: worldContract.config,
    }));
  }, [worldContract]);

  const handleFileChange = (event) => {
    const file = event.target.files[0];

    if (file) {
      const reader = new FileReader();

      reader.onload = (e) => {
        try {
          const parsedJson = JSON.parse(e.target.result);
          setFormData((prevFormData) => ({
            ...prevFormData,
            config: parsedJson,
          }));
        } catch (error) {
          console.error("Error parsing JSON:", error);
        }
      };

      reader.readAsText(file);
    }
  };

  return (
    <>
      <form className="form-horizontal" role="form">
        <div className="mb-2 row">
          <label className="col-md-2 col-form-label">Config file</label>
          <div className="col-md-10">
            <input
              type="file"
              className="form-control"
              onChange={handleFileChange}
            />
          </div>
        </div>
      </form>
      <ReactJson src={formData.config} theme="monokai" collapsed={2} />
    </>
  );
});

export default DashboardConfig;
