import _merge from "lodash/merge";

function EmbedIframe(props) {
  const { component, dashboardComponent } = props;

  const cmpOptions = component.component_options;
  const dbcOptions = dashboardComponent.dbc_component_options;
  //const dbcExtraOptions = dashboardComponent.dbc_component_extra_options;

  const options = {};

  const mergedOptions = _merge({}, options, cmpOptions, dbcOptions);

  const dataSource = dashboardComponent.dbc_data_source;
  

  return (
    <>
      <div className="card h-100 mb-0">
        <div className="card-body">
        {/* <h4 className="header-title">
            {mergedOptions.title ? `${mergedOptions.title}` : null}
          </h4>
          {mergedOptions.subtitle ? (
            <p className="card-subtitle mb-2 text-muted">
              {mergedOptions.subtitle}
            </p>
          ) : null} */}
          <iframe
            src={`${dataSource.src}`}
            width="100%"
            height="100%"
            style={{ border: "none" }}
          />
        </div>
      </div>
    </>
  );
}

export default EmbedIframe;
