import _merge from "lodash/merge";
import TransformData from "../helpers/data-transformer";
import Skeleton from "react-loading-skeleton";
import DbcFooter from "./dbc-footer";
import { useEffect } from "react";
import VisibilitySensor from "react-visibility-sensor";
import { Link, useLocation } from "react-router-dom";

function Counter(props) {
  const location = useLocation();
  const {
    component,
    dashboardComponent,
    dataQuery,
    coreSearchParams,
    handleVisibilitySensorChange,
  } = props;

  const cmpOptions = component.cmp_options;
  const dbcOptions = dashboardComponent.dbc_component_options;
  //const dbcExtraOptions = dashboardComponent.dbc_component_extra_options;

  const options = {
    title: null,
    prefix: null,
    suffix: null,
    label: null,
    decimals: 0,
  };

  const mergedOptions = _merge({}, options, cmpOptions, dbcOptions);

  // if (dataQuery.isLoading) {
  //   return <div>Loading...</div>;
  // }

  const data = TransformData({
    component,
    dashboardComponent,
    data: dataQuery?.data,
  });

  return (
    <>
      <VisibilitySensor
        onChange={handleVisibilitySensorChange}
        partialVisibility={true}
      >
        <div className="card h-100 mb-0">
          <div className="card-body">
            <Link
              to={`/dashboards/component/${dashboardComponent?.dbc_id}${
                coreSearchParams && Object.keys(coreSearchParams).length
                  ? `?${new URLSearchParams(coreSearchParams).toString()}`
                  : ""
              }`}
            >
              <h4 className="header-title">
                {mergedOptions.title ? `${mergedOptions.title}` : null}
              </h4>
            </Link>

            <div className="text-center">
              <h4 className="my-3 text-center">
                {!dataQuery.isLoading ? (
                  <>
                    {!data || typeof data === "undefined" ? (
                      <span>-</span>
                    ) : isNaN(data) ? (
                      <>
                        {mergedOptions.link ? (
                          <>
                            <a href={data} target="_blank">
                              {mergedOptions.link.text || mergedOptions.label}
                            </a>
                          </>
                        ) : (
                          <>
                            <span>{data}</span>
                          </>
                        )}
                      </>
                    ) : (
                      <>
                        {mergedOptions.prefix
                          ? `${mergedOptions.prefix}`
                          : null}
                        <span data-plugin="counterup">
                          {new Intl.NumberFormat("en-US", {
                            minimumFractionDigits: mergedOptions.decimals,
                            maximumFractionDigits: mergedOptions.decimals,
                            useGrouping: true,
                          }).format(data)}
                        </span>
                        {mergedOptions.suffix
                          ? `${mergedOptions.suffix}`
                          : null}
                      </>
                    )}
                  </>
                ) : (
                  <>
                    <Skeleton />
                  </>
                )}
              </h4>
              <p className="text-muted mb-0">
                {mergedOptions.label ? `${mergedOptions.label}` : null}
              </p>
            </div>
          </div>
          {location.pathname.startsWith("/dashboards/component") ? (
            <DbcFooter {...props} />
          ) : null}
        </div>
      </VisibilitySensor>
    </>
  );
}

export default Counter;
