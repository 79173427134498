import { Link } from "react-router-dom";

function Topnav(props) {
  return (
    <div className="topnav">
      <div className="container-fluid">
        <nav className="navbar navbar-light navbar-expand-lg topnav-menu">
          <div className="collapse navbar-collapse" id="topnav-menu-content">
            <ul className="navbar-nav">
              <li className="nav-item">
                <Link
                  className="nav-link  arrow-none"
                  to="/dashboards"                 
                  role="button"                
                >
                  <i className="ri-dashboard-line me-1" /> Dashboards                  
                </Link>
              </li>
            </ul>
          </div>
        </nav>
      </div>
    </div>
  );
}

export default Topnav;
