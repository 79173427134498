import { useRef } from "react";
import ApiService from "../../../services/api";
import DashboardAbi from "./dashboard-abi";
import DashboardBasicinfo from "./dashboard-basicinfo";
import DashboardConfig from "./dashboard-config";
import { useLocation, useNavigate } from "react-router-dom";
import Emitter from "../../../services/emitter";
import DashboardIndexer from "./dashboard-indexer";

function DashboardSettings(props) {
  const basicInfoRef = useRef();
  const abiRef = useRef();
  const configRef = useRef();
  const indexerRef = useRef();
  const navigate = useNavigate();
  const location = useLocation();

  const handleSaveDashboardSettings = async () => {
    const basicInfoData = basicInfoRef.current.getFormData();
    const abiData = abiRef.current.getFormData();
    const configData = configRef.current.getFormData();
    const indexerData = indexerRef.current.getFormData();

    try {
      const response = await ApiService.post("dashboard/settings", {
        basicInfo: basicInfoData,
        abiData: abiData,
        configData: configData,
        indexerData: indexerData,
      });

      const responseData = await response.json();

      if (responseData.status === "error") {
        throw new Error(response.message);
      }

      window.$.toast({
        heading: "Success",
        text: "Save dashboard success",
        position: "top-right",
        icon: "success",
      });
      window.$("#dashboard-settings-modal").modal("hide");

      if (location.pathname !== `/dashboard/${basicInfoData.dbd_slug}`) {
        navigate(`/dashboard/${basicInfoData.dbd_slug}`);
      } else {
        Emitter.emit("SAVE_DASHBOARD_SETTINGS_SUCCESS");
      }
    } catch (err) {
      window.$.toast({
        heading: "Error",
        text: "Save dashboard failed",
        position: "top-right",
        icon: "error",
      });
    }
  };

  return (
    <>
      <div
        id="dashboard-settings-modal"
        className="modal fade"
        tabIndex={-1}
        role="dialog"
        aria-labelledby="fullScreenModalLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-fullscreen">
          <div className="modal-content">
            <div className="modal-header">
              <h4 className="modal-title" id="fullScreenModalLabel">
                Dashboard Details
              </h4>
              <button
                type="button"
                className="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
              />
            </div>
            <div className="modal-body">
              <ul className="nav nav-tabs">
                <li className="nav-item">
                  <a
                    href="#dashboardBasicInfo"
                    data-bs-toggle="tab"
                    aria-expanded="false"
                    className="nav-link active"
                  >
                    <span className="d-none d-sm-inline-block">Basic Info</span>
                  </a>
                </li>
                <li className="nav-item">
                  <a
                    href="#dashboardAbi"
                    data-bs-toggle="tab"
                    aria-expanded="false"
                    className="nav-link"
                  >
                    <span className="d-none d-sm-inline-block">ABI</span>
                  </a>
                </li>
                <li className="nav-item">
                  <a
                    href="#dashboardConfig"
                    data-bs-toggle="tab"
                    aria-expanded="false"
                    className="nav-link"
                  >
                    <span className="d-none d-sm-inline-block">Config</span>
                  </a>
                </li>
                <li className="nav-item">
                  <a
                    href="#dashboardIndexer"
                    data-bs-toggle="tab"
                    aria-expanded="false"
                    className="nav-link"
                  >
                    <span className="d-none d-sm-inline-block">Indexer</span>
                  </a>
                </li>
              </ul>
              <div className="tab-content">
                <div className="tab-pane show active" id="dashboardBasicInfo">
                  <DashboardBasicinfo ref={basicInfoRef} {...props} />
                </div>
                <div className="tab-pane" id="dashboardAbi">
                  <DashboardAbi ref={abiRef} {...props} />
                </div>
                <div className="tab-pane" id="dashboardConfig">
                  <DashboardConfig ref={configRef} {...props} />
                </div>
                <div className="tab-pane" id="dashboardIndexer">
                  <DashboardIndexer ref={indexerRef} {...props} />
                </div>
              </div>
            </div>
            <div className="modal-footer">
              <button
                type="button"
                className="btn btn-light"
                data-bs-dismiss="modal"
              >
                Close
              </button>
              <button
                type="button"
                className="btn btn-primary"
                onClick={handleSaveDashboardSettings}
              >
                Save changes
              </button>
            </div>
          </div>
          {/* /.modal-content */}
        </div>
        {/* /.modal-dialog */}
      </div>
      {/* /.modal */}
    </>
  );
}

export default DashboardSettings;
