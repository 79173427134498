import { Link, useParams, useSearchParams } from "react-router-dom";
import PageBreadcrumb from "../../layouts/components/page-breadcrumb";
import DashboardComponent from "../../../app/components/dashboard-component";
import { useQuery } from "@tanstack/react-query";
import config from "../../../app/config";
import Skeleton from "react-loading-skeleton";
import { BreadcrumbsItem } from "react-breadcrumbs-dynamic";
import { useContext, useEffect } from "react";
import { UserContext } from "../../../app/contexts/user-context";
import DashboardComponentSettings from "../../../app/components/modals/dashboard-component/dashboard-component-settings";
import Emitter from "../../../app/services/emitter";

const DashboardsComponent = () => {
  const { dbcId } = useParams();
  const [searchParams, setSearchParams] = useSearchParams();
  const { user } = useContext(UserContext);

  const dashboardsComponentQuery = useQuery({
    queryKey: ["dashboardsComponent"],
    queryFn: async () => {
      try {
        const _coreSearchParams = getCoreSearchParams();

        let queryParams = { ..._coreSearchParams };

        const response = await fetch(
          `${config.api.url}/dashboards/component/${dbcId}${
            Object.keys(queryParams).length
              ? `?${new URLSearchParams(queryParams).toString()}`
              : ""
          }`,
          {
            headers: {
              "Cache-Control": "no-cache",
            },
          }
        );
        if (!response.ok) {
          throw new Error("Network response was not ok");
        }
        return response.json();
      } catch (err) {
        // Handle or log the error, if needed
        console.error("Error fetching:", err);
        return null;
      }
    },
  });

  useEffect(() => {
    Emitter.on("SAVE_DASHBOARD_COMPONENT_SETTINGS_SUCCESS", (newValue) => {
      dashboardsComponentQuery.refetch();
    });

    return () => {
      Emitter.off("SAVE_DASHBOARD_COMPONENT_SETTINGS_SUCCESS");
    };
  });

  const getCoreSearchParams = () => {
    const allSearchParams = searchParams;

    const _coreSearchParams = {};
    for (const [key, value] of allSearchParams.entries()) {
      const parts = key.split("_");
      const lastPart = parts[parts.length - 1] || null;

      let param_key;
      if (!/^dbc\d+$/.test(lastPart)) {
        param_key = parts.join("_");
        _coreSearchParams[param_key] = value.toLowerCase();
      }
    }

    return _coreSearchParams;
  };

  const coreSearchParams = getCoreSearchParams();

  return (
    <>
      <BreadcrumbsItem to="/dashboards">Dashboards</BreadcrumbsItem>
      <BreadcrumbsItem
        to={`/dashboard/${dashboardsComponentQuery.data?.mainDashboard.dbd_slug}`}
      >
        {dashboardsComponentQuery.data?.mainDashboard.dbd_name}
      </BreadcrumbsItem>

      <PageBreadcrumb
        title={`${dashboardsComponentQuery.data?.mainDashboard.dbd_name}`}
      />

      {user && user.role === "super_admin" ? (
        <div className="row">
          <div className="col-sm-12">
            <div className="float-sm-end">
              <form className="d-flex align-items-center flex-wrap">
                <button
                  type="button"
                  className="btn mb-2 ms-2 mb-sm-0 btn-sm btn-success"
                  data-bs-toggle="modal"
                  data-bs-target="#dashboard-component-settings-modal"
                >
                  Edit Dasboard Component
                </button>
              </form>
            </div>
          </div>
        </div>
      ) : null}

      <div className="row mt-4">
        <div className="col-12">
          <div style={{ height: "800px" }}>
            {dashboardsComponentQuery.isLoading ? (
              <Skeleton />
            ) : (
              <DashboardComponent
                // key={index}
                component={dashboardsComponentQuery?.data?.component}
                dashboardComponent={
                  dashboardsComponentQuery?.data?.dashboardComponent
                }
                query={dashboardsComponentQuery?.data?.query}
                dashboard={dashboardsComponentQuery?.data?.dashboard}
                mainDashboard={dashboardsComponentQuery?.data?.mainDashboard}
                coreSearchParams={coreSearchParams}
              />
            )}
          </div>
        </div>
      </div>

      <DashboardComponentSettings
        component={dashboardsComponentQuery?.data?.component}
        dashboardComponent={dashboardsComponentQuery?.data?.dashboardComponent}
        mainDashboard={dashboardsComponentQuery.data?.mainDashboard}
        query={dashboardsComponentQuery?.data?.query}
      />
    </>
  );
};

export default DashboardsComponent;
