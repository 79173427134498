import React, { useEffect, useRef, useState } from "react";
//import Chart from "chart.js/auto";
import { Chart, registerables } from "chart.js";
import _merge from "lodash/merge";
import TransformData from "../helpers/data-transformer";
import ChartjsPluginWatermark from "chartjs-plugin-watermark";
import DbcQueryParams from "./dbc-query-params";
import Skeleton from "react-loading-skeleton";
import DbcFooter from "./dbc-footer";
import { isMobile } from "react-device-detect";
import "chartjs-adapter-date-fns";
import { Link } from "react-router-dom";
import VisibilitySensor from "react-visibility-sensor";

Chart.register(...registerables);

const ChartjsChart = (props) => {
  const {
    component,
    dashboardComponent,
    dataQuery,
    query,
    dbcSearchParams,
    prevDbcSearchParams,
    handleVisibilitySensorChange,
  } = props;

  const cmpOptions = component.component_options;
  const dbcOptions = dashboardComponent.dbc_component_options;
  const dbcExtraOptions = dashboardComponent.dbc_component_extra_options;

  const chartRef = useRef(null);
  const [chart, setChart] = useState(null);

  // Default options
  const options = {
    responsive: true,
    maintainAspectRatio: false,
    plugins: {
      legend: {
        position: "top",
      },
      title: {
        display: true,
        text: null,
      },
    },
    watermark: {
      image: "/images/watermark.png",

      x: 0,
      y: 0,

      width: 100,
      height: 50,

      opacity: 1,

      alignX: "middle",
      alignY: "middle",

      position: "back",
    },
  };

  const mergedOptions = _merge({}, options, dbcExtraOptions, {
    plugins: {
      title: {
        text: dbcOptions.title,
      },
    },
  });

  const queryParams = query.qry_query_params || [];

  useEffect(() => {
    if (dataQuery.isLoading) return;
    // if (
    //   chart &&
    //   JSON.stringify(prevDbcSearchParams) === JSON.stringify(dbcSearchParams)
    // )
    //   return;

    const ctx = chartRef.current.getContext("2d");

    if (chart) {
      // If chart instance already exists, destroy it before creating a new one
      chart.destroy();
    }

    const data = TransformData({
      component,
      dashboardComponent,
      data: dataQuery?.data,
    });

    //Apply time format on labels
    if (
      data &&
      mergedOptions.scales &&
      mergedOptions.scales.x &&
      mergedOptions.scales.x.type === "time"
    ) {
      //mergedOptions.scales = {};
      data.labels = data.labels.map((label) => new Date(label));
    }

    const newChart = new Chart(ctx, {
      data: data,
      options: mergedOptions,
    });

    setChart(newChart);

    // Cleanup: destroy the chart when the component unmounts
    return () => {
      newChart.destroy();
    };
  }, [dataQuery]);

  // if (dataQuery.isLoading) {
  //   return <div>Loading...</div>;
  // }

  return (
    <VisibilitySensor
      onChange={handleVisibilitySensorChange}
      partialVisibility={true}
    >
      <div className={`card  mb-0 ${!isMobile ? "h-100" : "h-100"}`}>
        <div className="card-body">
          <Link to={`/dashboards/component/${dashboardComponent.dbc_id}`}>
            <h4 className="header-title">
              {dbcOptions.title ? `${dbcOptions.title}` : null}
            </h4>
          </Link>
          {dbcOptions.subtitle ? (
            <p className="card-subtitle mb-2 text-muted">
              {dbcOptions.subtitle}
            </p>
          ) : null}

          <div
            className="chart-container"
            style={{
              position: "relative",
              width: "100%",
              height: !isMobile ? "90%" : "80%",
            }}
          >
            <canvas ref={chartRef} />
          </div>

          {dataQuery.isLoading ? (
            <div className="text-center">
              <Skeleton width={"25%"} />
            </div>
          ) : null}
        </div>
        <DbcFooter {...props} />
        {/* {queryParams.length ? (
        <div className="card-footer">
          <DbcQueryParams {...props} />
        </div>
      ) : null} */}
      </div>
    </VisibilitySensor>
  );
};

export default ChartjsChart;
