import { Routes, Route, useNavigate, useLocation } from "react-router-dom";
import DefaultLayout from "./layouts/default";
import Home from "./views/home";
import Dashboards from "./views/dashboards";
import Dashboard from "./views/dashboard";
import DashboardsComponent from "./views/dashboards-component";
import Tag from "./views/tag";

import { useEffect } from "react";
import Login from "./views/auth/login";
import AuthLayout from "./layouts/auth";
import ProjectsNew from "./views/projects/new";
import Spectate from "./views/spectate";
//import DashboardsManage from "./views/dashboards-manage/dashboards-manage";
//import ManageLayout from "./layouts/manage";

function DefaultApp() {
  const navigate = useNavigate();
  const location = useLocation();

  // Redirect to the "/dashboards" route when the root path is visited
  // useEffect(() => {
  //   if (location.pathname === "/") {
  //     navigate("/dashboards");
  //   }
  // }, [navigate]);

  return (
    <Routes>
      <Route path="/" element={<DefaultLayout />}>
        <Route index element={<Dashboards />} />
        <Route path="dashboards" element={<Dashboards />} />
        {/* <Route path="dashboard/:slug" element={<Dashboard />} />    */}
        <Route path="dashboard/*" element={<Dashboard />} />
        <Route
          path="dashboards/component/:dbcId"
          element={<DashboardsComponent />}
        />
        {/* <Route path="manage" element={<ManageLayout />}>
          <Route path="dashboard" element={<DashboardsManage />} />
        </Route>         */}
        <Route path="projects/new" element={<ProjectsNew />} />
        {/* <Route path="tag/*" element={<Tag />} /> */}        
        <Route path="*" element={<Dashboards />} />
      </Route>
      <Route path="/auth" element={<AuthLayout />}>
        <Route path="login" element={<Login />} />
      </Route>
      <Route path="/spectate" element={<Spectate />} />
    </Routes>
  );
}

export default DefaultApp;
