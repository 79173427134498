import { useRef } from "react";
import ApiService from "../../../services/api";
import DashboardComponentBasicinfo from "./dashboard-component-basicinfo";

import { useLocation, useNavigate } from "react-router-dom";
import Emitter from "../../../services/emitter";
import DashboardComponentQuery from "./dashboard-component-query";



function DashboardComponentSettings(props) {
  const basicInfoRef = useRef();
  const queryRef = useRef();

  const navigate = useNavigate();
  const location = useLocation();

  const handleSaveDashboardComponentSettings = async () => {
    const basicInfoData = basicInfoRef.current.getFormData();
    const queryData = queryRef.current.getFormData();


    try {
      const response = await ApiService.post("dashboard/component/settings", {
        basicInfo: basicInfoData,
        queryData: queryData       
      });

      const responseData = await response.json();

      if (responseData.status === "error") {
        throw new Error(response.message);
      }

      window.$.toast({
        heading: "Success",
        text: "Save dashboard component success",
        position: "top-right",
        icon: "success",
      });
      window.$("#dashboard-component-settings-modal").modal("hide");

      // if (location.pathname !== `/dashboard/${basicInfoData.dbd_slug}`) {
      //   navigate(`/dashboard/${basicInfoData.dbd_slug}`);
      // } else {
         Emitter.emit("SAVE_DASHBOARD_COMPONENT_SETTINGS_SUCCESS");
      // }
    } catch (err) {
      window.$.toast({
        heading: "Error",
        text: "Save dashboard component failed",
        position: "top-right",
        icon: "error",
      });
    }
  };



  return (
    <>
      <div
        id="dashboard-component-settings-modal"
        className="modal fade"
        tabIndex={-1}
        role="dialog"
        aria-labelledby="fullScreenModalLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-fullscreen">
          <div className="modal-content">
            <div className="modal-header">
              <h4 className="modal-title" id="fullScreenModalLabel">
                Dashboard Component Details
              </h4>
              <button
                type="button"
                className="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
              />
            </div>
            <div className="modal-body">
              <ul className="nav nav-tabs">
                <li className="nav-item">
                  <a
                    href="#dashboardComponentBasicInfo"
                    data-bs-toggle="tab"
                    aria-expanded="false"
                    className="nav-link active"
                  >
                    <span className="d-none d-sm-inline-block">Basic Info</span>
                  </a>
                </li>
                <li className="nav-item">
                  <a
                    href="#dashboardComponentQuery"
                    data-bs-toggle="tab"
                    aria-expanded="false"
                    className="nav-link"
                  >
                    <span className="d-none d-sm-inline-block">Query</span>
                  </a>
                </li>
              </ul>
              <div className="tab-content">
                <div className="tab-pane show active" id="dashboardComponentBasicInfo">
                   <DashboardComponentBasicinfo ref={basicInfoRef} {...props} />
                </div>
                <div className="tab-pane " id="dashboardComponentQuery">
                   <DashboardComponentQuery ref={queryRef} {...props} />
                </div>
              </div>
            </div>
            <div className="modal-footer">
              <button
                type="button"
                className="btn btn-light"
                data-bs-dismiss="modal"
              >
                Close
              </button>
              <button
                type="button"
                className="btn btn-primary"
                onClick={handleSaveDashboardComponentSettings}
              >
                Save changes
              </button>
            </div>
          </div>
          {/* /.modal-content */}
        </div>
        {/* /.modal-dialog */}
      </div>
      {/* /.modal */}
    </>
  );
}

export default DashboardComponentSettings;
