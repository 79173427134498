import { useQuery } from "@tanstack/react-query";
import { forwardRef, useEffect, useImperativeHandle, useState } from "react";
import config from "../../../config";
import ApiService from "../../../services/api";

const DashboardComponentBasicinfo = forwardRef((props, ref) => {
  const { dashboardComponent, component } = props;
  const [formData, setFormData] = useState({});

  useEffect(() => {
    if (!dashboardComponent || !component) return;

    setFormData((prevFormData) => ({
      ...prevFormData,
      ...dashboardComponent,
    }));
  }, [dashboardComponent, component]);

  useImperativeHandle(ref, () => ({
    getFormData: () => formData,
  }));

  return (
    <>
      <form className="form-horizontal" role="form">
        <div className="mb-2 row">
          <label className="col-md-2 col-form-label">Component Options</label>
          <div className="col-md-10">
            <textarea
              className="form-control"
              rows={5}
              required
              value={JSON.stringify(formData.dbc_component_options)}
              onChange={(e) => {
                setFormData((prevFormData) => ({
                  ...prevFormData,
                  dbc_component_options: JSON.parse(e.target.value),
                }));
              }}
            />
          </div>
        </div>
      </form>
    </>
  );
});

export default DashboardComponentBasicinfo;
