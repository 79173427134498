import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";
import { Line } from "react-chartjs-2";
import _merge from "lodash/merge";
import TransformData from "../helpers/data-transformer";
import ChartjsPluginWatermark from "chartjs-plugin-watermark";

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
  ChartjsPluginWatermark
);

function LineChart(props) {
  const { component, dashboardComponent, dataQuery } = props;

  const cmpOptions = component.component_options;
  const dbcOptions = dashboardComponent.dbc_component_options;
  const dbcExtraOptions = dashboardComponent.dbc_component_extra_options;

  // Default options
  const options = {
    responsive: true,
    plugins: {
      legend: {
        position: "top",
      },
      title: {
        display: true,
        text: null,
      },
    },
    watermark: {
      image: "http://localhost:3000/images/watermark.png",

      x: 0,
      y: 0,

      width: 100,
      height: 50,

      opacity: 1,

      alignX: "middle",
      alignY: "middle",

      position: "back",
    },
  };

  const mergedOptions = _merge({}, options, dbcExtraOptions, {
    plugins: {
      title: {
        text: dbcOptions.title,
      },
    },
  });

  //const customOptions = { ...defaultOptions, ...options };

  if (dataQuery.isLoading) {
    return <div>Loading...</div>;
  }

  const data = TransformData({
    component,
    dashboardComponent,
    data: dataQuery.data,
  });

  return (
    <>
      <div style={{ width: "500px" }}>
        <Line data={data} options={mergedOptions} />
      </div>
    </>
  );
}

export default LineChart;
