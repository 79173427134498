import React, { createContext, useCallback, useEffect, useState } from "react";
import AuthService from "../services/auth";

const UserContext = createContext();

const UserContextProvider = ({ children }) => {
  const [accessToken, setAccessToken] = useState(null);
  const [user, setUser] = useState(null);

  const initialize = async () => {
    try {
      const data = await AuthService.me();
      if (data && data.user) {
        setUser(data.user);
      }
      // Other code to execute after AuthService.me() completes
    } catch (error) {
      // Handle errors if necessary
      console.error("Error fetching data:", error);
    }
  };

  useEffect(() => {
    initialize();

    // const accessToken = localStorage.getItem(`ACCESS_TOKEN`);

    // if(accessToken){
    //     setAccessToken(accessToken);
    // }
  }, []);

  const login = (userData) => {
    setUser(userData);
  };

  const logout = () => {
    setUser(null);
  };

  // const user = {
  //   accessToken: accessToken
  // };

  return (
    <UserContext.Provider value={{ user, login, logout }}>
      {children}
    </UserContext.Provider>
  );
};

export { UserContext, UserContextProvider };
