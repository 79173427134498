import _merge from "lodash/merge";
import _startCase from "lodash/startCase";
//import DataTable from "datatables.net-dt";
import { useEffect, useRef, useState } from "react";
import TransformData from "../helpers/data-transformer";
import DbcQueryParams from "./dbc-query-params";
import Skeleton from "react-loading-skeleton";
import DbcFooter from "./dbc-footer";
import { isMobile } from "react-device-detect";
import { Link } from "react-router-dom";
import * as primodiumMethods from "../../app/helpers/projects/primodium";
import VisibilitySensor from "react-visibility-sensor";
import moment from "moment";

const Table = (props) => {
  const {
    component,
    dashboardComponent,
    dataQuery,
    query,
    dbcSearchParams,
    prevDbcSearchParams,
    dashboard,
    mainDashboard,
    coreSearchParams,
    handleVisibilitySensorChange,
  } = props;

  const cmpOptions = component.component_options;
  const dbcOptions = dashboardComponent.dbc_component_options;
  //const dbcExtraOptions = dashboardComponent.dbc_component_extra_options;

  const tableRef = useRef(null);

  const [dataTable, setDataTable] = useState(null);

  const options = {};

  const mergedOptions = _merge({}, options, cmpOptions, dbcOptions);

  // if (dataQuery.isLoading) {
  //   return <div>Loading...</div>;
  // }

  const queryParams = query ? query.qry_query_params : [];

  const tableId = `table_${dashboardComponent.dbc_id}`;

  useEffect(() => {
    if (dataQuery.isLoading) return;
    //if(dataTable && JSON.stringify(prevDbcSearchParams) === JSON.stringify(dbcSearchParams) ) return;

    // Check if dataTable is already initialized and destroy it
    // if (dataTable) {
    //   dataTable.clear().destroy();
    // }

    // const newDataTable = window.$(tableRef.current).DataTable({
    //   data: data.data,
    //   columns: data.columns.map((col) => ({ data: col, title: col })),
    //   scrollY: "300px", // Set the height of the table
    //   scrollX: true, // Enable horizontal scrolling if needed
    //   scrollCollapse: true,
    //   //paging: false, // Disable pagination
    //   fixedHeader: {
    //     header: true,
    //     footer: false,
    //   },
    // });

    const data = TransformData({
      component,
      dashboardComponent,
      data: dataQuery?.data,
    });

    if (!data || !data.data) return;

    const newDataTable = window
      .$(tableRef.current)
      .on("preInit.dt", function () {
        window.dispatchEvent(new Event("resize"));
      })
      .DataTable({
        // language: {
        //   paginate: {
        //     previous: "<i class='mdi mdi-chevron-left'>",
        //     next: "<i class='mdi mdi-chevron-right'>",
        //   },
        // },
        data: data.data,
        columns: data.columns.map((col) => ({
          className: "no-wrap",
          targets: "_all",
          data: col,
          title:
            (dbcOptions.mappings &&
              dbcOptions.mappings[col] &&
              dbcOptions.mappings[col].title) ||
            _startCase(col),
          render: function (data, type, row) {
            if (type !== "display") return data;

            let modifiedData = data;

            //Apply data shorten for 66bytes hex
            if (/^(0x)?[0-9a-zA-Z]{66}$/.test(modifiedData)) {
              modifiedData =
                modifiedData.slice(0, 10) + "..." + modifiedData.slice(-8);
            }

            //Apply column mappings
            if (dbcOptions.mappings && dbcOptions.mappings[col]) {
              //Apply column value mappings
              modifiedData =
                (dbcOptions.mappings[col]["data"] &&
                  dbcOptions.mappings[col]["data"][data]) ||
                modifiedData;
              //return dbcOptions.mappings[col]["data"][data] || data;

              //Apply shorten
              if (dbcOptions.mappings[col]["shorten"]) {
                modifiedData =
                  modifiedData.slice(0, 7) + "..." + modifiedData.slice(-5);
              }

              //Apply column function mappings
              if (dbcOptions.mappings[col]["method"]) {
                if (mainDashboard.dbd_slug === "primodium" || mainDashboard.dbd_slug === "primodium11" || mainDashboard.dbd_slug === "primodium11_1") {
                  const method = dbcOptions.mappings[col]["method"];
                  modifiedData = primodiumMethods[method](data);
                }
              }

              //Apply column link mappings
              if (dbcOptions.mappings[col]["link"]) {
                let text = dbcOptions.mappings[col]["link"].text;
                let path = dbcOptions.mappings[col]["link"].path;
                let params = dbcOptions.mappings[col]["link"].params;
                let queryParams = [];
                for (let param of params) {
                  if (!param.key) continue;

                  let queryParam = { key: param.key, value: null };
                  if (param.col) {
                    //TODO
                  } else {
                    queryParam.value = data;
                  }

                  queryParams.push(queryParam);
                }

                return `<a href="/dashboard/${
                  mainDashboard.dbd_slug
                }/${path}?${queryParams
                  .map((param) => `${param.key}=${param.value}`)
                  .join("&")}" target="_blank">${text || modifiedData}</a>`;
              }

              //Apply image
              if (dbcOptions.mappings[col]["image"]) {
                let src = data;
                return `<img src="${src}" class="avatar-sm img-thumbnail rounded-circle"/>`;
              }

              return modifiedData;
            }

            //Color Hex
            if (/^#([0-9a-fA-F]{3}|[0-9a-fA-F]{6})$/.test(data)) {
              return `<div class="sp-palette"><span title="${data}" class="sp-thumb-el sp-thumb-dark"><span class="sp-thumb-inner" style="background-color:${data};"></span></span></div>&nbsp;${data}`;
            }

            //Ethereum Address
            if (/^(0x)?[0-9a-fA-F]{40}$/.test(data)) {
              modifiedData =
                modifiedData.slice(0, 7) + "..." + modifiedData.slice(-5);
              return `<a href="/dashboard/${mainDashboard.dbd_slug}/account?address=${data}" >${modifiedData}</a>`;
              //return `<a href="${mainDashboard.dbd_options.block_explorer}/address/${data}" target="_blank">${data}</a>`;
            }

            if (col === "spectate") {
              //const randomNumber = Math.floor(Math.random() * 9000) + 1000;
              // modifiedData = data.replace(
              //   /skystrife_(\d+)_session(\S+?)(?=\.yeomen\.ai|$)/,
              //   `$&${randomNumber}`
              // );
              return `<a href="${modifiedData}" target="_blank">Spectate</a>`;
            }

            //Convert to localtime
            if (
              moment(modifiedData, "YYYY-MM-DDTHH:mm:ss.SSSZ", true).isValid()
            ) {
              return moment
                .utc(modifiedData)
                .local()
                .format("YYYY-MM-DD HH:mm:ss");
            } else if (
              moment(modifiedData, "YYYY-MM-DD HH:mm", true).isValid()
            ) {
              return moment
                .utc(modifiedData)
                .local()
                .format("YYYY-MM-DD HH:mm");
            } else if (moment(modifiedData, "YYYY-MM-DD", true).isValid()) {
              return moment.utc(modifiedData).local().format("YYYY-MM-DD");
            }

            return modifiedData;
          },
        })),
        scrollY: queryParams && queryParams.length ? "450px" : "515px", // Set the height of the table
        scrollX: true, // Enable horizontal scrolling if needed
        scrollCollapse: true,
        //paging: false, // Disable pagination
        fixedHeader: {
          header: true,
          footer: false,
        },
        order: [],
      });

    setDataTable(newDataTable);

    // Cleanup on component unmount
    return () => {
      newDataTable.clear().destroy();
    };
  }, [dataQuery]);

  return (
    <>
      <VisibilitySensor
        onChange={handleVisibilitySensorChange}
        partialVisibility={true}
      >
        <div className={`card  mb-0 ${!isMobile ? "h-100" : ""}`}>
          <div className="card-body">
            <Link
              to={`/dashboards/component/${dashboardComponent?.dbc_id}${
                coreSearchParams && Object.keys(coreSearchParams).length
                  ? `?${new URLSearchParams(coreSearchParams).toString()}`
                  : ""
              }`}
            >
              <h4 className="header-title">
                {mergedOptions.title ? `${mergedOptions.title}` : null}
              </h4>
            </Link>
            {mergedOptions.subtitle ? (
              <p className="card-subtitle mb-2 text-muted">
                {mergedOptions.subtitle}
              </p>
            ) : null}

            {/* <table ref={tableRef} className="table" style={{ width: "100%" }}>
            <thead>
              <tr>{data && data.columns.map((col) => <th>{col}</th>)}</tr>
            </thead>
          </table> */}

            <table
              ref={tableRef}
              className="table w-100"
              //style={{ width: "100%" }}
            ></table>

            {dataQuery.isLoading ? (
              <div className="text-center">
                <Skeleton width={"25%"} />
              </div>
            ) : null}
          </div>
          <DbcFooter {...props} />
          {/* {queryParams && queryParams.length ? (
          <div className="card-footer">
            <DbcQueryParams {...props} />
          </div>
        ) : null} */}
        </div>
      </VisibilitySensor>
    </>
  );
};

export default Table;
