import Skeleton from "react-loading-skeleton";
import { Link } from "react-router-dom";

const RelatedDashboards = (props) => {
  const { dashboardQuery } = props;

  return (
    <div className="row mt-4">
      <div className="col-12">
        <div className="card">
          <div className="card-body">
            <h4 className="header-title mb-3">Related Dashboards</h4>

            {dashboardQuery.isLoading ? <Skeleton /> : null}
            {dashboardQuery.data?.relatedDashboards?.map((dashboard) => {
              return (
                <div
                  className="d-flex align-items-start"
                  key={dashboard.dbd_id}
                >
                  <div className="avatar-md me-3">
                    <div className="avatar-title bg-light ">
                      <img
                        src={`${dashboard.dbd_options.logo}`}
                        alt={`${dashboard.dbd_name}`}
                        className="avatar-md "
                        style={{ borderRadius: "8px" }}
                      />
                    </div>
                  </div>
                  <div className="flex-1">
                    <h4 className="my-1">
                      <Link to={`/dashboard/${dashboard.dbd_slug}`}>
                        {dashboard.dbd_name}
                      </Link>
                    </h4>
                    <div className="button-list">
                      {dashboard.dbd_options.tags.map((tag, index) => {
                        return (
                          <button
                            key={index}
                            type="button"
                            className="btn btn-light btn-xs waves-effect waves-light"
                          >
                            {/* <Link to={`/dashboards?tags=${tag}`}>{tag}</Link> */}
                            <Link to={`/${tag}`}>{tag}</Link>
                          </button>
                        );
                      })}
                    </div>

                    <p className="text-muted mt-2">
                      Creator: {dashboard.dbd_options.creator || "N/A"}
                    </p>

                    {/* <p className="text-muted mt-2">
                  Created {moment(dashboard.dbd_created_at).fromNow()}
                </p> */}
                  </div>
                </div>
              );
            })}
          </div>
        </div>
      </div>
    </div>
  );
};

export default RelatedDashboards;
