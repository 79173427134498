import { useQuery } from "@tanstack/react-query";
import { useContext, useEffect, useState } from "react";
import ReactJson from "react-json-view";
import config from "../config";
import { UserContext } from "../contexts/user-context";
import Skeleton from "react-loading-skeleton";
import { decodeFunctionData, decodeEventLog } from "viem";
var JSONbig = require('json-bigint');

function WorldContract(props) {
  const { mainDashboard, worldContract } = props;
  const user = useContext(UserContext);
  const [filteredAbi, setFilteredAbiAbi] = useState([]);

  // const worldDataQuery = useQuery({
  //   queryKey: [`world-data-${mainDashboard?.dbd_slug}`],
  //   queryFn: async () => {
  //     try {
  //       const response = await fetch(
  //         //`/contracts/${mainDashboard?.dbd_slug}/abi.json`
  //         `${config.api.url}/dashboard/${mainDashboard?.dbd_slug}/world`
  //       );
  //       if (!response.ok) {
  //         throw new Error("Network response was not ok");
  //       }
  //       return response.json();
  //     } catch (err) {
  //       // Handle or log the error, if needed
  //       console.error("Error fetching:", err);
  //       return null;
  //     }
  //   },
  //   enabled: mainDashboard ? true : false,
  // });

  // const worldAbiQuery = useQuery({
  //   queryKey: [`world-abi-${mainDashboard?.dbd_slug}`],
  //   queryFn: async () => {
  //     try {
  //       const response = await fetch(
  //         `/contracts/${mainDashboard?.dbd_slug}/abi.json`
  //       );
  //       if (!response.ok) {
  //         throw new Error("Network response was not ok");
  //       }
  //       return response.json();
  //     } catch (err) {
  //       // Handle or log the error, if needed
  //       console.error("Error fetching:", err);
  //       return null;
  //     }
  //   },
  //   enabled: mainDashboard ? true : false,
  // });

  // const worldConfigQuery = useQuery({
  //   queryKey: [`world-config-${mainDashboard?.dbd_slug}`],
  //   queryFn: async () => {
  //     try {
  //       const response = await fetch(
  //         `/contracts/${mainDashboard?.dbd_slug}/config.json`
  //       );
  //       if (!response.ok) {
  //         throw new Error("Network response was not ok");
  //       }
  //       return response.json();
  //     } catch (err) {
  //       // Handle or log the error, if needed
  //       console.error("Error fetching:", err);
  //       return null;
  //     }
  //   },
  //   enabled: mainDashboard ? true : false,
  // });

  const [signaturesDetails, setSignaturesDetails] = useState({});

  const fetchSignatureDetails = async (signature) => {
    if (!signaturesDetails[signature.signature]) {
      try {
        const response = await fetch(
          `${config.api.url}/dashboard/${mainDashboard?.dbd_slug}/signature/${signature.signature}`,
          {
            headers: {
              // Accept: "application/json",
              "Content-Type": "application/json",
              //"x-access-token": user?.accessToken || null,
            },
            credentials: 'include',     
          }
        );
        if (!response.ok) {
          throw new Error("Network response was not ok");
        }
        let data = await response.json();
        setSignaturesDetails({
          ...signaturesDetails,
          [signature.signature]: data,
        });
      } catch (err) {
        // Handle or log the error, if needed
        console.error("Error fetching:", err);
        //return null;
      }
    }
  };

  useEffect(() => {
    if (!worldContract || !worldContract.abi) return;

    let abi = worldContract.abi;
    let filteredAbi = abi && abi.filter((abi) => {
      if (abi.type === "error") return false;
      if (abi.type === "view") return false;
      if (abi.stateMutability === "view") return false;

      if (
        abi.type === "function" &&
        [
          "batchCall",
          "batchCallFrom",
          "call",
          "callFrom",
          "unregisterStoreHook",
          "unregisterSystemHook",
          "installModule",
          "installRootModule",
          "registerDelegation",
          "registerFunctionSelector",
          "registerNamespace",
          "registerNamespaceDelegation",
          "registerRootFunctionSelector",
          "registerStoreHook",
          "registerSystem",
          "registerSystemHook",
          "registerTable",
          "revokeAccess",
          "setDynamicField",
          "setStaticField",
          "setField",
          "setRecord",
          "spliceDynamicData",
          "spliceStaticData",
          "deleteRecord",
          "grantAccess",
          "initialize",
          "popFromDynamicField",
          "pushToDynamicField",
          "transferBalanceToAddress",
          "transferBalanceToNamespace",
          "transferOwnership",
        ].includes(abi.name)
      )
        return false;

      if (
        abi.type === "event" &&
        [
          "HelloStore",
          "HelloWorld",
          "Store_DeleteRecord",
          "Store_SetRecord",
          "Store_SpliceDynamicData",
          "Store_SpliceStaticData",
        ].includes(abi.name)
      )
        return false;

      return true;
    });
    console.log(abi);
    console.log(filteredAbi);

    setFilteredAbiAbi(filteredAbi);
  }, [worldContract]);

  const [decoderFormData, setDecoderFormData] = useState({
    type: "function",
    encoded_data: null,
    topics: null,
    decoded_data: null,
    decoded_error: null,
  });

  const handleDecoderClick = () => {
    let contractAbi = worldContract.abi;
    let decoded_data, decoded_error;
    switch (decoderFormData.type) {
      case "function":
        try {
          decoded_data = decodeFunctionData({
            abi: contractAbi,
            data: decoderFormData.encoded_data,
          });

         
          if (decoded_data.functionName === 'callFrom') {
            let args_call_data = decoded_data.args[decoded_data.args.length-1];
            decoded_data.args_call_data_decoded = decodeFunctionData({
                abi: contractAbi,
                data: args_call_data,
            });
          }

          if (decoded_data.functionName === "batchCall") {
            let args_batchCalls = decoded_data.args[decoded_data.args.length-1];
            
            for (let args_batchCall of args_batchCalls) {
              let args_batchCall_data = args_batchCall.callData;
              args_batchCall.callData_decoded = decodeFunctionData({
                abi: contractAbi,
                data: args_batchCall_data,
              });
            }
          }
        } catch (err) {
          decoded_error = err.message;
        }
        break;
      case "event":
        try {
          let topics = decoderFormData.topics
            .split("\n")
            .filter((value) => value.trim() !== "");

          decoded_data = decodeEventLog({
            abi: contractAbi,
            data: decoderFormData.encoded_data,
            topics: [...topics],
          });
        } catch (err) {
          decoded_error = err.message;
        }
        break;
      default:
        break;
    }

    setDecoderFormData((prevData) => ({
      ...prevData,
      decoded_data: decoded_data?JSON.parse(JSONbig.stringify(decoded_data)):decoded_data,
      decoded_error: decoded_error,
    }));
  };

  const [transactionFormData, setTransactionFormData] = useState({   
    transaction_hash: null,
    decoded_data: null
  });

  const handleTransactionSearchClick = async() => {
    try {
      const response = await fetch(
        `${config.api.url}/dashboard/${mainDashboard?.dbd_slug}/transaction/${transactionFormData.transaction_hash}`,
        {
          headers: {
            // Accept: "application/json",
            "Content-Type": "application/json",
            //"x-access-token": user?.accessToken || null,
          },
          credentials: 'include',     
        }
      );
      if (!response.ok) {
        throw new Error("Network response was not ok");
      }
      let data = await response.json();
      setTransactionFormData({
        ...transactionFormData,
        decoded_data: data,
      });
     console.log(data)
    } catch (err) {
      // Handle or log the error, if needed
      console.error("Error fetching:", err);
      //return null;
    }
  }

  return (
    <>
      <div
        id="dashboard-abi-modal"
        className="modal fade"
        tabIndex={-1}
        role="dialog"
        aria-labelledby="fullScreenModalLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-fullscreen">
          <div className="modal-content">
            <div className="modal-header">
              <h4 className="modal-title" id="fullScreenModalLabel">
                Contract Details
              </h4>
              <button
                type="button"
                className="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
              />
            </div>
            <div className="modal-body">
              <ul className="nav nav-tabs">
                <li className="nav-item">
                  <a
                    href="#worldAbi"
                    data-bs-toggle="tab"
                    aria-expanded="false"
                    className="nav-link active"
                  >
                    <span className="d-none d-sm-inline-block">ABI</span>
                  </a>
                </li>
                <li className="nav-item">
                  <a
                    href="#worldConfig"
                    data-bs-toggle="tab"
                    aria-expanded="true"
                    className="nav-link"
                  >
                    <span className="d-none d-sm-inline-block">Config</span>
                  </a>
                </li>
                <li className="nav-item">
                  <a
                    href="#worldTransactions"
                    data-bs-toggle="tab"
                    aria-expanded="true"
                    className="nav-link"
                  >
                    <span className="d-none d-sm-inline-block">
                      Transactions
                    </span>
                  </a>
                </li>
                <li className="nav-item">
                  <a
                    href="#worldDecoder"
                    data-bs-toggle="tab"
                    aria-expanded="true"
                    className="nav-link"
                  >
                    <span className="d-none d-sm-inline-block">Decoder</span>
                  </a>
                </li>
              </ul>
              <div className="tab-content">
                <div className="tab-pane show active" id="worldAbi">
                  <ReactJson src={filteredAbi} theme="monokai" collapsed={2} />
                </div>
                <div className="tab-pane" id="worldConfig">
                  <ReactJson
                    src={worldContract?.config}
                    theme="monokai"
                    collapsed={2}
                  />
                </div>
                <div className="tab-pane" id="worldTransactions">
                 <h5>Transaction</h5>
                 <form role="form">
                    <div className="mb-2">
                      <label className="form-label">Transaction hash</label>
                      <input
                        className="form-control"
                        value={transactionFormData.transaction_hash}
                        onChange={(e) => {
                          setTransactionFormData((prevData) => ({
                            ...prevData,
                            transaction_hash: e.target.value,
                          }));
                        }}
                      />
                    </div>

                    <button
                      type="button"
                      className="btn btn-primary mb-2"
                      onClick={() => handleTransactionSearchClick()}
                    >
                      Search
                    </button>

                     {transactionFormData.decoded_data ? (
                      <ReactJson
                        src={transactionFormData.decoded_data}
                        theme="monokai"
                        collapsed={2}
                      />
                    ) : null}                    
                  </form>               

                  <h5>Signatures</h5>
                  <div
                    className="accordion accordion-flush"
                    id="worldContractSignatures-accordion"
                  >
                    {worldContract?.signatures?.map((signature, index) => {
                      return (
                        <div className="accordion-item" key={index}>
                          <h2
                            className="accordion-header"
                            id={`worldContractSignatures-heading${index}`}
                          >
                            <button
                              className="accordion-button collapsed"
                              type="button"
                              data-bs-toggle="collapse"
                              data-bs-target={`#worldContractSignatures-collapse${index}`}
                              aria-expanded="false"
                              aria-controls={`worldContractSignatures-collapse${index}`}
                              onClick={() => {
                                fetchSignatureDetails(signature);
                              }}
                            >
                              {signature.text}_{signature.signature} (
                              {signature.total_transactions})
                            </button>
                          </h2>
                          <div
                            id={`worldContractSignatures-collapse${index}`}
                            className="accordion-collapse collapse"
                            aria-labelledby={`worldContractSignatures-heading${index}`}
                            data-bs-parent="#worldContractSignatures-accordion"
                          >
                            <div className="accordion-body">
                              {!signaturesDetails[signature.signature] ? (
                                <Skeleton />
                              ) : null}
                              {signaturesDetails[signature.signature] ? (
                                <ReactJson
                                  src={signaturesDetails[signature.signature]}
                                  theme="monokai"
                                  collapsed={2}
                                />
                              ) : null}

                              {/* <table className="table w-100">
                                <thead>
                                  <tr>
                                    <td>Block</td>
                                    <td>Timestamp</td>
                                    <td>From</td>
                                    <td>To</td>
                                    {signaturesDetails[signature.signature] &&
                                      Object.keys(
                                        signaturesDetails[signature.signature]
                                          ?.transactions[0]
                                      ).map((fieldArg, index) => (
                                        <td key={index}>Arg {fieldArg}</td>
                                      ))}
                                  </tr>
                                </thead>
                                <tbody>
                                  {signaturesDetails[
                                    signature.signature
                                  ]?.transactions.map((transaction, index) => {
                                    return (
                                      <tr key={index}>
                                        <td>{transaction.block_number}</td>
                                        <td>{transaction.block_timestamp}</td>
                                        <td>{transaction.from_address}</td>
                                        <td>{transaction.to_address}</td>
                                        <td>
                                          {JSON.stringify(
                                            transaction.args_units
                                          )}
                                        </td>
                                      </tr>
                                    );
                                  })}
                                </tbody>
                              </table> */}
                            </div>
                          </div>
                        </div>
                      );
                    })}
                  </div>
                </div>
                <div className="tab-pane" id="worldDecoder">
                  <form role="form">
                    <div className="mb-2">
                      <label className="form-label">Type</label>
                      <select
                        className="form-control"
                        value={decoderFormData.type}
                        onChange={(e) => {
                          setDecoderFormData((prevData) => ({
                            ...prevData,
                            type: e.target.value,
                            decoded_data: null,
                            decoded_error: null,
                          }));
                        }}
                      >
                        <option value={"function"}>Function</option>
                        <option value={"event"}>Event</option>
                      </select>
                    </div>
                    <div className="mb-2">
                      <label className="form-label">Encoded Data</label>
                      <textarea
                        className="form-control"
                        value={decoderFormData.encoded_data}
                        onChange={(e) => {
                          setDecoderFormData((prevData) => ({
                            ...prevData,
                            encoded_data: e.target.value,
                          }));
                        }}
                      ></textarea>
                    </div>
                    {decoderFormData.type === "event" ? (
                      <div className="mb-2">
                        <label className="form-label">Topics</label>
                        <textarea
                          className="form-control"
                          value={decoderFormData.topics}
                          onChange={(e) => {
                            setDecoderFormData((prevData) => ({
                              ...prevData,
                              topics: e.target.value,
                            }));
                          }}
                        ></textarea>
                      </div>
                    ) : null}

                    <button
                      type="button"
                      className="btn btn-primary mb-2"
                      onClick={() => handleDecoderClick()}
                    >
                      Decode
                    </button>

                    {decoderFormData.decoded_data ? (
                      <ReactJson
                        src={decoderFormData.decoded_data}
                        theme="monokai"
                        collapsed={2}
                      />
                    ) : null}
                    {decoderFormData.decoded_error ? (
                      <pre className="text-danger">
                        {decoderFormData.decoded_error}
                      </pre>
                    ) : null}
                  </form>
                </div>
              </div>
            </div>
            <div className="modal-footer">
              <button
                type="button"
                className="btn btn-light"
                data-bs-dismiss="modal"
              >
                Close
              </button>
            </div>
          </div>
          {/* /.modal-content */}
        </div>
        {/* /.modal-dialog */}
      </div>
      {/* /.modal */}
    </>
  );
}

export default WorldContract;
