const TransformCounterData = ({ component, dashboardComponent, data }) => {
  const dbcOptions = dashboardComponent.dbc_component_options;
  if (!data.data) return null;

  const column = dbcOptions.column;
  const row = dbcOptions.row;
  if (!data.data[row]) return null;
  return data.data[row][column] || null;
};

// const TransformLineChartData = ({ component, dashboardComponent, data }) => {
//   const dbcOptions = dashboardComponent.dbc_component_options;

//   const title = dbcOptions.title;
//   const X_column = dbcOptions.X_column;
//   const Y_column = dbcOptions.Y_column;
//   const X_column_labels = data.data.map((item) => item[X_column]);
//   const Y_column_values = data.data.map((item) => item[Y_column]);

//   return {
//     labels: X_column_labels,
//     datasets: [
//       {
//         label: title,
//         data: Y_column_values,
//         fill: false,
//         borderColor: "rgba(75,192,192,1)",
//       },
//     ],
//   };
// };

const TransformChartjsChartData = ({ component, dashboardComponent, data }) => {
  const dbcOptions = dashboardComponent.dbc_component_options;
  if (!data.data) return;

  const columnX = Object.keys(dbcOptions.columns).find(
    (key) => dbcOptions.columns[key] === "x"
  );

  const columnsY = Object.keys(dbcOptions.columns).filter(
    (key) => dbcOptions.columns[key] === "y"
  );

  const columnSeries = Object.keys(dbcOptions.columns).find(
    (key) => dbcOptions.columns[key] === "series"
  );

  let rootData;
  if (
    dashboardComponent.dbc_data_source &&
    dashboardComponent.dbc_data_source.root
  ) {
    rootData = data[dashboardComponent.dbc_data_source.root];
  } else {
    rootData = data.data;
  }

  const dynamicSeriesKeys = columnSeries
    ? new Set(rootData.map((item) => item[columnSeries]))
    : [];
  //Set dbcOptions.series or override
  if (!dbcOptions.series) dbcOptions.series = [];
  for (const dynamicSeriesKey of dynamicSeriesKeys) {
    if (!dbcOptions.series[dynamicSeriesKey]) {
      dbcOptions.series[dynamicSeriesKey] = {
        name: dynamicSeriesKey,
        type: "line",
      };
    }
  }

  let labels = [...new Set(rootData.map((item) => item[columnX]))];

  const datasets = Object.keys(dbcOptions.series).map((key) => {
    const series = dbcOptions.series[key];
    let values = [];
    if (columnSeries) {
      //filter((item)=>item[columnSeries] === key)
      for (var label of labels) {
        let item = rootData.find(
          (item) =>
            item[columnX] === label &&
            item[columnSeries] === key &&
            item[columnsY[0]]
        );
        if (item) {
          var numberWithoutCommas = String(item[columnsY[0]]).replace(/,/g, "");
          if (!isNaN(numberWithoutCommas)) {
            item[columnsY[0]] = Number(numberWithoutCommas);
          }

          values.push(item[columnsY[0]]);
        }else{
          values.push(0);
        }        
      }
      // values = rootData.map((item) => {
      //   if (item[columnSeries] === key && item[columnsY[0]]) {
      //     var numberWithoutCommas = String(item[columnsY[0]]).replace(/,/g, "");
      //     if (!isNaN(numberWithoutCommas)) {
      //       item[columnsY[0]] = Number(numberWithoutCommas);
      //     }
      //   }

      //   return item[columnSeries] === key ? item[columnsY[0]] : 0;
      // });
    } else {
      values = rootData.map((item) => {
        if (item[key]) {
          var numberWithoutCommas = String(item[key]).replace(/,/g, "");
          if (!isNaN(numberWithoutCommas)) {
            item[key] = Number(numberWithoutCommas);
          }
        }
        return item[key];
      });
    }

    //Limit 50 only
    if (series.type === "pie" || series.type === "doughnut") {
      const limit = 50;
      labels =
        labels.length > limit ? [...labels.slice(0, limit), "Other"] : labels;
      values =
        values.length > limit
          ? [
              ...values.slice(0, limit),
              values.slice(limit).reduce((acc, val) => acc + Number(val), 0),
            ]
          : values;
    }

    return {
      type: series.type || "line",
      label: series.name || key,
      data: values,
    };
  });

  return {
    labels: labels,
    datasets: datasets,
  };
};

const TransformTableData = ({ component, dashboardComponent, data }) => {
  const dbcOptions = dashboardComponent.dbc_component_options;
  if (!data.data) return;

  let rows, columns;
  if (
    dashboardComponent.dbc_data_source &&
    dashboardComponent.dbc_data_source.provider === "dune"
  ) {
    columns = data.result.metadata.column_names;
    rows = data.result.rows;
  } else {
    columns = data.data.length ? Object.keys(data.data[0]) : [];
    rows = data.data;
  }

  return { columns: columns, data: rows };
};

const TransformVisxHeatmapData = ({ component, dashboardComponent, data }) => {
  const dbcOptions = dashboardComponent.dbc_component_options;
  const grid = dbcOptions.grid || {
    maxX: null,
    maxY: null,
    minX: null,
    minY: null,
  };

  if (!data.data) return;

  // Convert string coordinates to integers
  const convertedData = data.data.map((entry) => ({
    x: parseInt(entry.x),
    y: parseInt(entry.y) * -1,
    count: parseFloat(entry.count),
  }));

  // Find the range of x and y values
  const xValues = convertedData.map((entry) => entry.x);
  const yValues = convertedData.map((entry) => entry.y);
  const maxX = Math.max(...xValues, grid.maxX);
  const maxY = Math.max(...yValues, grid.maxY);
  const minX = Math.min(...xValues, grid.minX);
  const minY = Math.min(...yValues, grid.minY);

  // Create bins
  const bins = [];

  for (let i = minX; i <= maxX; i++) {
    const binY = [];
    for (let j = minY; j <= maxY; j++) {
      const count = convertedData
        .filter((entry) => entry.x === i && entry.y === j)
        .reduce((sum, entry) => sum + entry.count, 0);
      binY.push({
        //bin: j - minY,
        bin: j,
        count: count,
      });
    }

    bins.push({
      //bin: i - minX,
      bin: i,
      bins: binY,
    });
  }

  return { bins: bins };
};

const TransformVisxColormapData = ({ component, dashboardComponent, data }) => {
  const dbcOptions = dashboardComponent.dbc_component_options;
  const color_pallets = dbcOptions.color_pallets || [];
  const grid = dbcOptions.grid || {
    maxX: null,
    maxY: null,
    minX: null,
    minY: null,
  };

  if (!data.data) return;

  // Convert string coordinates to integers
  const convertedData = data.data.map((entry) => ({
    x: parseInt(entry.x),
    y: parseInt(entry.y) * -1,
    value: parseInt(entry.value),
  }));

  // Find the range of x and y values
  const xValues = convertedData.map((entry) => entry.x);
  const yValues = convertedData.map((entry) => entry.y);
  const maxX = Math.max(...xValues, grid.maxX);
  const maxY = Math.max(...yValues, grid.maxY);
  const minX = Math.min(...xValues, grid.minX);
  const minY = Math.min(...yValues, grid.minY);

  // Create bins
  const bins = [];

  for (let i = minX; i <= maxX; i++) {
    const binY = [];
    for (let j = minY; j <= maxY; j++) {
      const row = convertedData.find((entry) => entry.x === i && entry.y === j);

      const value = row ? row.value : null;
      const color = value >= 0 ? color_pallets[value] : null;

      binY.push({
        //bin: j - minY,
        bin: j,
        value: value,
        color: color,
      });
    }

    bins.push({
      //bin: i - minX,
      bin: i,
      bins: binY,
    });
  }

  // const records = data.data.map((row) => ({
  //   ...row,
  //   color: color_pallets[row.value] || null,
  // }));

  return { bins };
};

const TransformHeatmapData = ({ component, dashboardComponent, data }) => {
  const dbcOptions = dashboardComponent.dbc_component_options;
  if (!data.data) return;

  return { data: data.data };
};

const TransformImageViewerData = ({ component, dashboardComponent, data }) => {
  const dbcOptions = dashboardComponent.dbc_component_options;
  if (!data.data) return;

  return { data: data.data };
};

// const TransformTextData = ({ component, dashboardComponent, data }) => {
//   const dbcOptions = dashboardComponent.dbc_component_options;
// console.log(dbcOptions)
//   const markdown = dbcOptions.dbc_component_options.content;

//   return <Markdown>{markdown}</Markdown>
// }

const TransformData = ({ component, dashboardComponent, data }) => {
  if (!component || !dashboardComponent || !data) return;
  // Your transformation logic

  switch (component.cmp_type) {
    case "counter":
      return TransformCounterData({ component, dashboardComponent, data });
    // case "line-chart":
    //   return TransformLineChartData({ component, dashboardComponent, data });
    case "chartjs-chart":
      return TransformChartjsChartData({ component, dashboardComponent, data });
    case "table":
      return TransformTableData({ component, dashboardComponent, data });
    case "visx-heatmap":
      return TransformVisxHeatmapData({ component, dashboardComponent, data });
    case "visx-colormap":
      return TransformVisxColormapData({ component, dashboardComponent, data });
    case "heatmap":
      return TransformHeatmapData({ component, dashboardComponent, data });
    case "image-viewer":
      return TransformImageViewerData({ component, dashboardComponent, data });
    // case "text":
    //     return TransformTextData({ component, dashboardComponent });
    default:
      return;
  }
};

export default TransformData;
