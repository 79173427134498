import { Link } from "react-router-dom";

function Topbar(props) {
  return (
    <div className="navbar-custom">
      <div className="container-fluid">
        <ul className="list-unstyled topnav-menu float-end mb-0">
          {/* <li className="d-none d-lg-block">
            <form className="app-search">
              <div className="app-search-box dropdown">
                <div className="input-group">
                  <input
                    type="search"
                    className="form-control"
                    placeholder="Search..."
                    id="top-search"
                  />
                  <button className="btn" type="submit">
                    <i className="fe-search" />
                  </button>
                </div>
                <div className="dropdown-menu dropdown-lg" id="search-dropdown">
                  
                  <div className="dropdown-header noti-title">
                    <h5 className="text-overflow mb-2">
                      Found <span className="text-danger">09</span> results
                    </h5>
                  </div>
                
                  <a
                    href="javascript:void(0);"
                    className="dropdown-item notify-item"
                  >
                    <i className="fe-home me-1" />
                    <span>Analytics Report</span>
                  </a>
                 
                  <a
                    href="javascript:void(0);"
                    className="dropdown-item notify-item"
                  >
                    <i className="fe-aperture me-1" />
                    <span>How can I help you?</span>
                  </a>
                
                  <a
                    href="javascript:void(0);"
                    className="dropdown-item notify-item"
                  >
                    <i className="fe-settings me-1" />
                    <span>User profile settings</span>
                  </a>
                 
                  <div className="dropdown-header noti-title">
                    <h6 className="text-overflow mb-2 text-uppercase">Users</h6>
                  </div>
                  <div className="notification-list">
                   
                    <a
                      href="javascript:void(0);"
                      className="dropdown-item notify-item"
                    >
                      <div className="d-flex">
                        <img
                          className="d-flex me-2 rounded-circle"
                          src="../assets/images/users/avatar-2.jpg"
                          alt="Generic placeholder image"
                          height={32}
                        />
                        <div>
                          <h5 className="m-0 font-14">Erwin E. Brown</h5>
                          <span className="font-12 mb-0">UI Designer</span>
                        </div>
                      </div>
                    </a>
                   
                    <a
                      href="javascript:void(0);"
                      className="dropdown-item notify-item"
                    >
                      <div className="d-flex">
                        <img
                          className="d-flex me-2 rounded-circle"
                          src="../assets/images/users/avatar-5.jpg"
                          alt="Generic placeholder image"
                          height={32}
                        />
                        <div>
                          <h5 className="m-0 font-14">Jacob Deo</h5>
                          <span className="font-12 mb-0">Developer</span>
                        </div>
                      </div>
                    </a>
                  </div>
                </div>
              </div>
            </form>
          </li> */}
        </ul>
        {/* LOGO */}
        <div className="logo-box">
          <Link to="/" className="logo logo-dark text-center">
            <span className="logo-sm">
              {/* <img src="../assets/images/logo-sm-dark.png" alt height={24} /> */}
               <span className="logo-lg-text-light">Y</span> 
            </span>
            <span className="logo-lg">
              {/* <img src="../assets/images/logo-dark.png" alt height={20} /> */}
               <span className="logo-lg-text-light">Yeomen.ai</span> 
            </span>
          </Link>
          <Link to="/" className="logo logo-light text-center">
            <span className="logo-sm">
              {/* <img src="../assets/images/logo-sm.png" alt height={24} /> */}
              <span className="logo-lg-text-light">Y</span> 
            </span>
            <span className="logo-lg">
              {/* <img src="../assets/images/logo-light.png" alt height={20} /> */}
              <span className="logo-lg-text-light">Yeomen.ai</span> 
            </span>
          </Link>
        </div>
        <ul className="list-unstyled topnav-menu topnav-menu-left m-0">
          <li>
            <button className="button-menu-mobile waves-effect waves-light">
              <i className="fe-menu" />
            </button>
          </li>
          <li>
            {/* Mobile menu toggle (Horizontal Layout)*/}
            <a
              className="navbar-toggle nav-link"
              data-bs-toggle="collapse"
              data-bs-target="#topnav-menu-content"
            >
              <div className="lines">
                <span />
                <span />
                <span />
              </div>
            </a>
            {/* End mobile menu toggle*/}
          </li>
          {/* <li className=" d-none d-xl-block">
            <Link
              className="nav-link  waves-effect waves-light"           
              to="/dashboards"        
              role="button"
            >
              Dashboards             
            </Link>
          </li> */}
          <li className="d-none d-xl-block">
            <Link
              className="nav-link   waves-effect waves-light"
              to="/dashboards"
              role="button"
            >
              <i className="ri-dashboard-line me-1" /> Dashboards
            </Link>
          </li>
        </ul>
        <div className="clearfix" />
      </div>
    </div>
  );
}

export default Topbar;
