import React from "react";
import ReactDOM from "react-dom/client";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
//import { BrowserRouter as Router } from "react-router-dom";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import { SkeletonTheme } from "react-loading-skeleton";
import { ThroughProvider } from "react-through";
import "react-loading-skeleton/dist/skeleton.css";
import { UserContextProvider } from "./app/contexts/user-context";

// Create a client
const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
    },
  },
});

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <>
    {/* <React.StrictMode> */}
    <QueryClientProvider client={queryClient}>
      <SkeletonTheme color="#424e5a" highlightColor="#4b5966">
        <ThroughProvider>
          <UserContextProvider>
            <App />
          </UserContextProvider>
        </ThroughProvider>
      </SkeletonTheme>
    </QueryClientProvider>
    {/* </React.StrictMode> */}
  </>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
