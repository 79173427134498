import { Breadcrumbs } from "react-breadcrumbs-dynamic";
import { Link } from "react-router-dom";

function PageBreadcrumb(props) {
  const { title } = props;

  return (
    <div className="row">
      <div className="col-12">
        <div className="page-title-box page-title-box-alt">
          <h4 className="page-title">{title}</h4>
          <div className="page-title-right">
            <Breadcrumbs
              container={"ol"}
              containerProps={{ className: "breadcrumb m-0" }}
              item={(item) => {
                return (
                  <li className="breadcrumb-item">
                    <Link to={item.to}>{item.children}</Link>
                  </li>
                );
              }}
              finalItem={(item) => {
                return (
                  <li className="breadcrumb-item active">{item.children}</li>
                );
              }}
            />
          </div>
        </div>
      </div>
    </div>
  );
}

export default PageBreadcrumb;
