import { useQuery } from "@tanstack/react-query";
import { forwardRef, useEffect, useImperativeHandle, useState } from "react";
import config from "../../../config";
import ApiService from "../../../services/api";

const DashboardComponentQuery = forwardRef((props, ref) => {
  const { query} = props;
  const [formData, setFormData] = useState({});

  useEffect(() => {
    if (!query) return;

    setFormData((prevFormData) => ({
      ...prevFormData,
      ...query,
    }));
  }, [query]);

  useImperativeHandle(ref, () => ({
    getFormData: () => formData,
  }));

  return (
    <>
      <form className="form-horizontal" role="form">
      <div className="mb-2 row">
          <label className="col-md-2 col-form-label">Title</label>
          <div className="col-md-10">
            <input
              type="text"
              className="form-control"
              required
              value={formData.qry_title}
              onChange={(e) => {
                setFormData((prevFormData) => ({
                  ...prevFormData,
                  qry_title: e.target.value,
                }));
              }}
            />
          </div>
        </div>
        <div className="mb-2 row">
          <label className="col-md-2 col-form-label">Query</label>
          <div className="col-md-10">
            <textarea
              className="form-control"
              rows={10}
              required
               value={formData.qry_query}
              onChange={(e) => {
                setFormData((prevFormData) => ({
                  ...prevFormData,
                  qry_query: e.target.value,
                }));
              }}
            />
          </div>
        </div>
      </form>
    </>
  );
});

export default DashboardComponentQuery;
