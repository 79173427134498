import { useQuery } from "@tanstack/react-query";
import { forwardRef, useEffect, useImperativeHandle, useState } from "react";
import config from "../../../config";
import ApiService from "../../../services/api";

const DashboardBasicinfo = forwardRef((props, ref) => {
  const { mainDashboard } = props;
  const [formData, setFormData] = useState({
    dbd_id: null,
    dbd_name: "",
    dbd_slug: "",
    dbd_status: "",
    dbd_options: {
      logo: "",
      description: "",
      tags: "",
      creator:"",
      world_address: "",
      initial_block: "",
      block_explorer: "",
    },
  });

  useEffect(() => {
    if (!mainDashboard) return;

    setFormData((prevFormData) => ({
      ...prevFormData,
      dbd_id: mainDashboard.dbd_id,
      dbd_name: mainDashboard.dbd_name,
      dbd_slug: mainDashboard.dbd_slug,
      dbd_status: mainDashboard.dbd_status,
      dbd_options: {
        ...prevFormData.dbd_options,
        logo: mainDashboard.dbd_options.logo,
        description: mainDashboard.dbd_options.description,
        tags: mainDashboard.dbd_options.tags,
        creator: mainDashboard.dbd_options.creator,
        world_address: mainDashboard.dbd_options.world_address,
        initial_block: mainDashboard.dbd_options.initial_block,
        block_explorer: mainDashboard.dbd_options.block_explorer,
      },
    }));

    if (window.$("#selectize-tags")[0]) {
      setTimeout(() => {
        window
          .$("#selectize-tags")[0]
          .selectize.setValue(mainDashboard.dbd_options.tags);
      }, 1000);
    }
  }, [mainDashboard]);

  const tagsQuery = useQuery({
    queryKey: ["tags"],
    queryFn: async () => {
      try {
        const response = await ApiService.get(`tags`,{});
        if (!response.ok) {
          throw new Error("Network response was not ok");
        }
        return response.json();
      } catch (err) {
        // Handle or log the error, if needed
        console.error("Error fetching:", err);
        return null;
      }
    },
  });
  //const tags = [{ tag: "MUD" }, { tag: "ETHEREUM" }, { tag: "REDSTONE" }];

  useEffect(() => {
    if(!tagsQuery?.data) return;
   

    window.$("#selectize-tags").selectize({
      maxItems: null,
      selectedValues: null,
      options: tagsQuery?.data?.tags,
      labelField: 'tag_tag',
      valueField: 'tag_tag',
      onChange: (values) => {
        // Update the form data when the selection changes

        setFormData((prevFormData) => ({
          ...prevFormData,
          dbd_options: {
            ...prevFormData.dbd_options,
            tags: values,
          },
        }));
      },
    });
  }, [tagsQuery?.data]);

  useImperativeHandle(ref, () => ({
    getFormData: () => formData,
  }));
  

  return (
    <>
      <form className="form-horizontal" role="form">
        <div className="mb-2 row">
          <label className="col-md-2 col-form-label">Dashboard Name</label>
          <div className="col-md-10">
            <input
              type="text"
              className="form-control"
              required
              value={formData.dbd_name}
              onChange={(e) => {
                setFormData((prevFormData) => ({
                  ...prevFormData,
                  dbd_name: e.target.value,
                }));
              }}
            />
          </div>
        </div>
        <div className="mb-2 row">
          <label className="col-md-2 col-form-label">Dashboard Url</label>
          <div className="col-md-10">
            <input
              type="text"
              className="form-control"
              required
              value={formData.dbd_slug}
              onChange={(e) => {
                setFormData((prevFormData) => ({
                  ...prevFormData,
                  dbd_slug: e.target.value,
                }));
              }}
            />
          </div>
        </div>
        <div className="mb-2 row">
          <label className="col-md-2 col-form-label">Description</label>
          <div className="col-md-10">
            <textarea
              className="form-control"
              rows={5}
              required
              value={formData.dbd_options.description}
              onChange={(e) => {
                setFormData((prevFormData) => ({
                  ...prevFormData,
                  dbd_options: {
                    ...prevFormData.dbd_options,
                    description: e.target.value,
                  },
                }));
              }}
            />
          </div>
        </div>
        <div className="mb-2 row">
          <label className="col-md-2 col-form-label">Logo Url</label>
          <div className="col-md-10">
            <input
              type="text"
              className="form-control"
              required
              value={formData.dbd_options.logo}
              onChange={(e) => {
                setFormData((prevFormData) => ({
                  ...prevFormData,
                  dbd_options: {
                    ...prevFormData.dbd_options,
                    logo: e.target.value,
                  },
                }));
              }}
            />
          </div>
        </div>

        <div className="mb-2 row">
          <label className="col-md-2 col-form-label">Tags</label>
          <div className="col-md-10">
            <select
              id="selectize-tags"
              value={formData.tags}
              required
              placeholder="Select options..."
            >
              <option value="">Select a tag...</option>
              {tagsQuery?.data?.tags.map((tag, index) => (
                <option value={tag.tag} key={index}>
                  {tag.tag}
                </option>
              ))}
              {/* <option value="AL">Alabama</option>
              <option value="WY" selected>
                Wyoming
              </option> */}
            </select>
            <div className="clearfix" />
          </div>
        </div>
        <div className="mb-2 row">
          <label className="col-md-2 col-form-label">Creator</label>
          <div className="col-md-10">
            <input
              type="text"
              className="form-control"
              value={formData.dbd_options.creator}
              required
              onChange={(e) => {
                setFormData((prevFormData) => ({
                  ...prevFormData,
                  dbd_options: {
                    ...prevFormData.dbd_options,
                    creator: e.target.value,
                  },
                }));
              }}
            />
          </div>
        </div>

        <div className="mb-2 row">
          <label className="col-md-2 col-form-label">Contract Address</label>
          <div className="col-md-10">
            <input
              type="text"
              className="form-control"
              value={formData.dbd_options.world_address}
              required
              onChange={(e) => {
                setFormData((prevFormData) => ({
                  ...prevFormData,
                  dbd_options: {
                    ...prevFormData.dbd_options,
                    world_address: e.target.value,
                  },
                }));
              }}
            />
          </div>
        </div>

        <div className="mb-2 row">
          <label className="col-md-2 col-form-label">
            Initial Block Number
          </label>
          <div className="col-md-10">
            <input
              type="text"
              className="form-control"
              value={formData.dbd_options.initial_block}
              required
              onChange={(e) => {
                setFormData((prevFormData) => ({
                  ...prevFormData,
                  dbd_options: {
                    ...prevFormData.dbd_options,
                    initial_block: e.target.value,
                  },
                }));
              }}
            />
          </div>
        </div>
        <div className="mb-2 row">
          <label className="col-md-2 col-form-label">Block Explorer Url</label>
          <div className="col-md-10">
            <input
              type="text"
              className="form-control"
              value={formData.dbd_options.block_explorer}
              required
              onChange={(e) => {
                setFormData((prevFormData) => ({
                  ...prevFormData,
                  dbd_options: {
                    ...prevFormData.dbd_options,
                    block_explorer: e.target.value,
                  },
                }));
              }}
            />
          </div>
        </div>
        <div className="mb-2 row">
          <label className="col-md-2 col-form-label">Status</label>
          <div className="col-md-2">
            <select
              className="form-control"
              value={formData.dbd_status}
              onChange={(e) => {
                setFormData((prevFormData) => ({
                  ...prevFormData,
                  dbd_status: e.target.value,
                }));
              }}
            >
              <option value={"active"}>Active</option>
              <option value={"inactive"}>Inactive</option>
            </select>
          </div>
        </div>
      </form>
    </>
  );
});

export default DashboardBasicinfo;
