import { useQuery } from "@tanstack/react-query";
import { useNavigate, useLocation } from "react-router-dom";
import config from "../config";
import React, { useCallback, useEffect, useState } from "react";
import { debounce } from "lodash";
import { isMobile } from "react-device-detect";
import Emitter from "../services/emitter";

const DbcQueryParam = (props) => {
  const {
    param,
    dashboard,
    dashboardComponent,
    dbcSearchParams,
    coreSearchParams,
    setSearchParams,
    debouncedSetSearchParams,
  } = props;
  const [options, setOptions] = useState(param.options || []);
 

  useEffect(() => {
    
    if (param.options) {      
      setOptions(prevOptions => ([...param.options]));      
    } 
  }, [param?.options]);

  const paramQuery = useQuery({
    queryKey: [`paramQuery_${dashboardComponent.dbc_id}_${param.key}`, coreSearchParams],
    queryFn: async () => {
      let method = "GET";

      let queryParams = { ...coreSearchParams };

      let url = null;
      if (param.data_source.url) {
        url = param.data_source.url;
      } else if (param.data_source.query_id) {
        url = `${config.api.url}/query/${param.data_source.query_id}`;
      }

      let headers = {};

      let fetchOptions = {
        method: method,
        headers: headers,
      };

      const response = await fetch(
        `${url}${
          Object.keys(queryParams).length
            ? "?" +
              new URLSearchParams({
                ...queryParams,
              })
            : ""
        }`,
        fetchOptions
      );

      if (!response.ok) {
        throw new Error("Network response was not ok");
      }

      const responseData = await response.json();

      if (
        responseData.status === "PENDING" ||
        responseData.status === "PROCESSING"
      ) {
        throw new Error("Wait for query data");
      }

      return responseData;
    },
    enabled: param.data_source ? true : false,
    retry: 15, // Number of retries before giving up
    retryDelay: (retryCount) => Math.min((retryCount + 1) * 2000, 30000), // Delay between retries (in milliseconds)
  });

  //const options = paramQuery.data ? paramQuery.data.data : param.options;

  const column =
    param.data_source && param.data_source.column
      ? param.data_source.column
      : null;

  const param_key = `${param.key}_dbc${dashboardComponent.dbc_id}`;
  //const param_value = dbcSearchParams[param.key] || param.value;
  const [param_value, setParamValue] = useState(
    dbcSearchParams[param.key] ||
      param.value ||
      (options[0] ? options[0][column] : 0)
  );

  useEffect(() => {
    if (paramQuery.isLoading) return;

    if (paramQuery.data) setOptions(paramQuery.data.data);
  }, [paramQuery]);


  if (param.type === "block_number" && options) {
   
    //Must be changed to dynamic
    const min = options[0] ? options[0][column] : 0;
    const max = options[options.length - 1]
      ? options[options.length - 1][column]
      : 0;

    const firstOption = options[0] ?? null;
    const lastOption = options[options.length - 1] || null;

    const currentOption = (() => {
      // const current_index = options.findIndex(
      //   (record) => record[param.key] === param_value
      // );

      // const option = options[current_index] || null;
      const option = options.find(
        (record) => record[param.key] === param_value
      );
      if (!option) return;

      return option;
    })();

    const previousOption = (() => {
      // const current_index = options.findIndex(
      //   (record) => record[param.key] === param_value
      // );

      // const option = options[current_index - 1] || null;
      const option = options.findLast(
        (record) => record[param.key] < param_value
      );
      if (!option) return;

      return option;
    })();

    const nextOption = (() => {
      // const current_index = options.findIndex(
      //   (record) => record[param.key] === param_value
      // );

      // const option = options[current_index + 1] || null;
      const option = options.find((record) => record[param.key] > param_value);
      if (!option) return;

      return option;
    })();

    return (
      <>
        {/* <label className="col-md-2 col-form-label">
            {param.key || "Label"}
          </label> */}
        <div className="col-md-7">
          <nav>
            <ul className="pagination">
              <li
                className={`page-item ${
                  !firstOption || firstOption === currentOption
                    ? "disabled"
                    : ""
                }`}
              >
                <span
                  className="page-link"
                  style={{ cursor: "pointer" }}
                  onClick={(e) => {
                    if (!firstOption) return;

                    const value = firstOption[column];
                    setParamValue(value);
                    setSearchParams({
                      [`${param_key}`]: value,
                    });
                  }}
                >
                  {isMobile ? "<<" : "First"}
                </span>
              </li>
              <li
                className={`page-item ${
                  !previousOption || previousOption === currentOption
                    ? "disabled"
                    : ""
                }`}
              >
                <span
                  className="page-link"
                  style={{ cursor: "pointer" }}
                  onClick={(e) => {
                    if (!previousOption) return;

                    const value = previousOption[column];
                    setParamValue(value);
                    setSearchParams({
                      [`${param_key}`]: value,
                    });
                  }}
                >
                  {isMobile ? "<" : "Previous"}
                </span>
              </li>
              <li className="page-item">
                <input
                  type={"number"}
                  className="form-control"
                  id={`floating${param.key}Input`}                  
                  placeholder={`Enter ${param.key}`}
                  value={param_value}
                  onChange={(e) => {
                    setParamValue(e.target.value);
                    debouncedSetSearchParams({
                      [`${param_key}`]: e.target.value,
                    });
                  }}
                  min={min}
                  max={max}
                  style={{ padding: "6px",width:'100px' }}
                />
              </li>

              <li
                className={`page-item ${
                  !nextOption || nextOption === currentOption ? "disabled" : ""
                }`}
              >
                <span
                  className="page-link"
                  style={{ cursor: "pointer" }}
                  onClick={(e) => {
                    if (!nextOption) return;

                    const value = nextOption[column];
                    setParamValue(value);
                    setSearchParams({
                      [`${param_key}`]: value,
                    });
                  }}
                >
                  {isMobile ? ">" : "Next"}
                </span>
              </li>
              <li
                className={`page-item ${
                  !lastOption || lastOption === currentOption ? "disabled" : ""
                }`}
              >
                <span
                  className="page-link"
                  style={{ cursor: "pointer" }}
                  onClick={(e) => {
                    if (!lastOption) return;

                    const value = lastOption[column];
                    setParamValue(value);
                    setSearchParams({
                      [`${param_key}`]: value,
                    });
                  }}
                >
                  {isMobile ? ">>" : "Last"}
                </span>
              </li>
            </ul>
          </nav>
        </div>
        <div className="col-md-5">
          <span className="ms-2" style={{ position: "relative", top: "6px" }}>
            Showing{" "}
            {options.findIndex((record) => record[param.key] === param_value) +
              1}{" "}
            of {options.length} entries
          </span>
        </div>

        {/* <div className="col-md-12">
          <label className="col-md-2 col-form-label">
            {param.key || "Label"}
          </label>

          <nav>
            <ul className="pagination">
              <li
                className={`page-item ${
                  !firstOption || firstOption === currentOption
                    ? "disabled"
                    : ""
                }`}
              >
                <span
                  className="page-link"
                  style={{ cursor: "pointer" }}
                  onClick={(e) => {
                    if (!firstOption) return;

                    const value = firstOption[column];

                    setSearchParams({
                      [`${param_key}`]: value,
                    });
                  }}
                >
                  First
                </span>
              </li>
              <li
                className={`page-item ${
                  !previousOption || previousOption === currentOption
                    ? "disabled"
                    : ""
                }`}
              >
                <span
                  className="page-link"
                  style={{ cursor: "pointer" }}
                  onClick={(e) => {
                    if (!previousOption) return;

                    const value = previousOption[column];

                    setSearchParams({
                      [`${param_key}`]: value,
                    });
                  }}
                >
                  Previous
                </span>
              </li>
              <li className="page-item">
                <input
                  type={"number"}
                  className="form-control"
                  id={`floating${param.key}Input`}
                  placeholder={`Enter ${param.key}`}
                  value={dbcSearchParams[param.key] || param.value}
                  onChange={(e) => {
                    setSearchParams({
                      [`${param_key}`]: e.target.value,
                    });
                  }}
                  min={min}
                  max={max}
                  style={{ padding: "6px" }}
                />
              </li>

              <li
                className={`page-item ${
                  !nextOption || nextOption === currentOption ? "disabled" : ""
                }`}
              >
                <span
                  className="page-link"
                  style={{ cursor: "pointer" }}
                  onClick={(e) => {
                    if (!nextOption) return;

                    const value = nextOption[column];

                    setSearchParams({
                      [`${param_key}`]: value,
                    });
                  }}
                >
                  Next
                </span>
              </li>
              <li
                className={`page-item ${
                  !lastOption || lastOption === currentOption ? "disabled" : ""
                }`}
              >
                <span
                  className="page-link"
                  style={{ cursor: "pointer" }}
                  onClick={(e) => {
                    if (!lastOption) return;

                    const value = lastOption[column];

                    setSearchParams({
                      [`${param_key}`]: value,
                    });
                  }}
                >
                  Last
                </span>
              </li>
            </ul>
          </nav>
        </div> */}

        {/* <div key={index} className="col-md-2">
          <div className="form-floating">
            <input
              type={"number"}
              className="form-control"
              id={`floating${param.key}Input`}
              placeholder={`Enter ${param.key}`}
              value={dbcSearchParams[param.key] || param.value}
              onChange={(e) => {
                setSearchParams({
                  [`${param.key}_dbc${dashboardComponent.dbc_id}`]:
                    e.target.value,
                });
              }}
              min={param.values.min}
              max={param.values.max}
            />

            <label htmlFor={`floating${param.key}Input`}>
              {param.key || "Label"}
            </label>
          </div>
        </div>
        <div key={index} className="col-md-6">
          <label
            className="col-md-2 col-form-label"
            htmlFor={`form-range${param.key}`}
          >
            {param.key || "Label"}
          </label>
          <input
            className="form-range"
            type="range"
            id={`form-range${param.key}`}
            name="range"
            min={param.values.min}
            max={param.values.max}
            value={dbcSearchParams[param.key] || param.value}
            onChange={(e) => {
              setSearchParams({
                [`${param.key}_dbc${dashboardComponent.dbc_id}`]:
                  e.target.value,
              });
            }}
          />
        </div> */}

        {/* <label
          className="col-md-2 col-form-label"
          htmlFor={`form-range${param.key}`}
        >
          {param.key || "Label"}
        </label>
        <div className="col-md-10 align-self-center">
          <input
            className="form-range"
            type="range"
            id={`form-range${param.key}`}
            name="range"
            min={0}
            max={100000000}
            onChange={(e) => {
              setSearchParams({
                [`${param.key}_dbc${dashboardComponent.dbc_id}`]:
                  e.target.value,
              });
            }}
          />
        </div> */}
      </>
    );
  } else if (param.type === "select") {
  } else if (param.type === "number" || param.type === "text") {
    return (
      <div className="col-md-2">
        <div className="form-floating">
          <input
            type={param.type || "text"}
            className="form-control"
            id={`floating${param.key}Input`}
            placeholder={`Enter ${param.key}`}
            value={dbcSearchParams[param.key] || param.value}
            onChange={(e) => {
              setSearchParams({
                [`${param.key}_dbc${dashboardComponent.dbc_id}`]:
                  e.target.value,
              });
            }}
          />
          <label htmlFor={`floating${param.key}Input`}>
            {param.key || "Label"}
          </label>
        </div>
      </div>
    );
  }
};

function DbcQueryParams(props) {
  const { query, dataQuery, dashboardComponent, dbcSearchParams, dashboard } =
    props;
  const navigate = useNavigate();
  const location = useLocation();

  // Get the existing query parameters
  const allSearchParams = new URLSearchParams(location.search);

  const setSearchParams = useCallback((params) => {
    const mergedSearchParams = new URLSearchParams({
      ...Object.fromEntries(allSearchParams.entries()),
      ...params,
    });
    //navigate(`?${mergedSearchParams.toString()}`);
    window.history.pushState(
      {},
      "",
      location.pathname + "?" + mergedSearchParams.toString()
    );
    Emitter.emit("HISTORY_PUSHSTATE");
  }, []);

  const debouncedSetSearchParams = useCallback(
    debounce((params) => setSearchParams(params), 500),
    [setSearchParams]
  );

  const queryParams = query.qry_query_params || [];

  // const renderElement = (param, index) => {
  //   const options = param.options;

  //   if (param.type === "block_number") {
  //     //Must be changed to dynamic
  //     param.values = {
  //       min: dashboard.dbd_options.initial_block,
  //       max: dashboard.dbd_options.latest_block,
  //     };

  //     // const paramQuery = useQuery({
  //     //   queryKey: [`paramQuery_${dashboardComponent.dbc_id}_${param.key}`, dbcSearchParams],
  //     //   queryFn: async () => {
  //     //     let method = "GET";

  //     //     let queryParams = { ...dbcSearchParams };

  //     //     let url = null;
  //     //     if (param.data_source.url) {
  //     //       url = param.data_source.url;
  //     //     } else if (param.data_source.query_id) {
  //     //       url = `${config.api.url}/query/${param.data_source.query_id}`;
  //     //     }

  //     //     let headers = {};

  //     //     let fetchOptions = {
  //     //       method: method,
  //     //       headers: headers,
  //     //     };

  //     //     const response = await fetch(
  //     //       `${url}${
  //     //         Object.keys(queryParams).length
  //     //           ? "?" +
  //     //             new URLSearchParams({
  //     //               ...queryParams,
  //     //             })
  //     //           : ""
  //     //       }`,
  //     //       fetchOptions
  //     //     );

  //     //     if (!response.ok) {
  //     //       throw new Error("Network response was not ok");
  //     //     }

  //     //     const responseData = await response.json();

  //     //     if (
  //     //       responseData.status === "PENDING" ||
  //     //       responseData.status === "PROCESSING"
  //     //     ) {
  //     //       throw new Error("Wait for query data");
  //     //     }

  //     //     return responseData;
  //     //   },
  //     //   enabled: dashboardComponent.dbc_data_source ? true : false,
  //     //   retry: 15, // Number of retries before giving up
  //     //   retryDelay: (retryCount) => Math.min((retryCount + 1) * 2000, 30000), // Delay between retries (in milliseconds)
  //     // });

  //     return (
  //       <>
  //         <div key={index} className="col-md-12">
  //           <label className="col-md-2 col-form-label">
  //             {param.key || "Label"}
  //           </label>

  //           <nav>
  //             <ul className="pagination">
  //               <li className="page-item disabled">
  //                 <a
  //                   className="page-link"
  //                   href="javascript: void(0);"
  //                   tabIndex={-1}
  //                 >
  //                   First
  //                 </a>
  //               </li>
  //               <li className="page-item disabled">
  //                 <a
  //                   className="page-link"
  //                   href="javascript: void(0);"
  //                   tabIndex={-1}
  //                 >
  //                   Previous
  //                 </a>
  //               </li>
  //               <li className="page-item">
  //                 <input
  //                   type={"number"}
  //                   className="form-control"
  //                   id={`floating${param.key}Input`}
  //                   placeholder={`Enter ${param.key}`}
  //                   value={dbcSearchParams[param.key] || param.value}
  //                   onChange={(e) => {
  //                     setSearchParams({
  //                       [`${param.key}_dbc${dashboardComponent.dbc_id}`]:
  //                         e.target.value,
  //                     });
  //                   }}
  //                   min={param.values.min}
  //                   max={param.values.max}
  //                   style={{ padding: "6px" }}
  //                 />
  //               </li>

  //               <li className="page-item">
  //                 <a className="page-link" href="javascript: void(0);">
  //                   Next
  //                 </a>
  //               </li>
  //               <li className="page-item">
  //                 <a className="page-link" href="javascript: void(0);">
  //                   Last
  //                 </a>
  //               </li>
  //             </ul>
  //           </nav>
  //         </div>

  //         {/* <div key={index} className="col-md-2">
  //           <div className="form-floating">
  //             <input
  //               type={"number"}
  //               className="form-control"
  //               id={`floating${param.key}Input`}
  //               placeholder={`Enter ${param.key}`}
  //               value={dbcSearchParams[param.key] || param.value}
  //               onChange={(e) => {
  //                 setSearchParams({
  //                   [`${param.key}_dbc${dashboardComponent.dbc_id}`]:
  //                     e.target.value,
  //                 });
  //               }}
  //               min={param.values.min}
  //               max={param.values.max}
  //             />

  //             <label htmlFor={`floating${param.key}Input`}>
  //               {param.key || "Label"}
  //             </label>
  //           </div>
  //         </div>
  //         <div key={index} className="col-md-6">
  //           <label
  //             className="col-md-2 col-form-label"
  //             htmlFor={`form-range${param.key}`}
  //           >
  //             {param.key || "Label"}
  //           </label>
  //           <input
  //             className="form-range"
  //             type="range"
  //             id={`form-range${param.key}`}
  //             name="range"
  //             min={param.values.min}
  //             max={param.values.max}
  //             value={dbcSearchParams[param.key] || param.value}
  //             onChange={(e) => {
  //               setSearchParams({
  //                 [`${param.key}_dbc${dashboardComponent.dbc_id}`]:
  //                   e.target.value,
  //               });
  //             }}
  //           />
  //         </div> */}

  //         {/* <label
  //           className="col-md-2 col-form-label"
  //           htmlFor={`form-range${param.key}`}
  //         >
  //           {param.key || "Label"}
  //         </label>
  //         <div className="col-md-10 align-self-center">
  //           <input
  //             className="form-range"
  //             type="range"
  //             id={`form-range${param.key}`}
  //             name="range"
  //             min={0}
  //             max={100000000}
  //             onChange={(e) => {
  //               setSearchParams({
  //                 [`${param.key}_dbc${dashboardComponent.dbc_id}`]:
  //                   e.target.value,
  //               });
  //             }}
  //           />
  //         </div> */}
  //       </>
  //     );
  //   } else if (param.type === "select") {
  //   } else if (param.type === "number" || param.type === "text") {
  //     return (
  //       <div key={index} className="col-md-2">
  //         <div className="form-floating">
  //           <input
  //             type={param.type || "text"}
  //             className="form-control"
  //             id={`floating${param.key}Input`}
  //             placeholder={`Enter ${param.key}`}
  //             defaultValue={dbcSearchParams[param.key] || param.value}
  //             onChange={(e) => {
  //               setSearchParams({
  //                 [`${param.key}_dbc${dashboardComponent.dbc_id}`]:
  //                   e.target.value,
  //               });
  //             }}
  //           />
  //           <label htmlFor={`floating${param.key}Input`}>
  //             {param.key || "Label"}
  //           </label>
  //         </div>
  //       </div>
  //     );
  //   }
  // };

  return (
    <>
      <div className="row" style={{width: `${isMobile?'100%':'50%'}`}}>
        {queryParams.map((param, index) => (
          // <>{renderElement(param, index)}</>
          <React.Fragment key={index}>
            <DbcQueryParam
              param={param}
              {...props}
              setSearchParams={setSearchParams}
              debouncedSetSearchParams={debouncedSetSearchParams}
            />
          </React.Fragment>
        ))}
      </div>
    </>
  );
}

export default DbcQueryParams;
