import React, { useCallback, useEffect, useState } from "react";
import { useQuery } from "@tanstack/react-query";
import Counter from "./counter";
import LineChart from "./line-chart";
import ChartjsChart from "./chartjs-chart";
import Text from "./text";
import config from "../config";
import Table from "./table";
import { useLocation } from "react-router-dom";
import VisxHeatmap from "./visx-heatmap";
import VisxColormap from "./visx-colormap";
import EmbedIframe from "./embed-iframe";
import { usePrevious } from "@uidotdev/usehooks";
import Emitter from "../services/emitter";
import Heatmap from "./heatmap";
import ImageViewer from "./image-viewer";

function DashboardComponent(props) {
  const { component, dashboardComponent, query, coreSearchParams } = props;
  const location = useLocation();
  const [dbcSearchParams, setDbcSearchParams] = useState(null);
  const prevDbcSearchParams = usePrevious(dbcSearchParams);
  const [enabled, setEnabled] = useState(false);
  const [dataCache, setDataCache] = useState(false);
  //let dataCache = true;

  // Get the existing query parameters
  const allSearchParams = new URLSearchParams(location.search);

  //const dbcSearchParams = {};
  //const coreSearchParams = {};

  useEffect(() => {
    // Get the existing query parameters
    const allSearchParams = new URLSearchParams(location.search);

    // Set default params
    if (query && query.qry_query_params) {
      const newDbcSearchParams = {};
      for (const param of query.qry_query_params) {
        const column =
          param.data_source && param.data_source.column
            ? param.data_source.column
            : null;

        if (!param.value) {
          if (column) {
            param.value =
              param.options && param.options.length
                ? param.options[param.options.length - 1][column]
                : 0;
          } else {
            param.value = coreSearchParams[param.key];
          }
        }
        newDbcSearchParams[param.key] = param.value;
      }
      setDbcSearchParams((prevParams) => ({
        ...prevParams,
        ...newDbcSearchParams,
      }));
    }

    // Set URL params
    for (const [key, value] of allSearchParams.entries()) {
      const parts = key.split("_");
      const lastPart = parts[parts.length - 1] || null;

      if (lastPart !== `dbc${dashboardComponent.dbc_id}`) continue;

      let paramKey = parts.slice(0, -1).join("_");
      setDbcSearchParams((prevParams) => ({
        ...prevParams,
        [paramKey]: value,
      }));
    }
  }, [location.search, query, coreSearchParams, dashboardComponent]);

  useEffect(() => {
    Emitter.on("HISTORY_PUSHSTATE", (newValue) => {
      // Get the existing query parameters
      const allSearchParams = new URLSearchParams(window.location.search);

      // Set default params
      if (query && query.qry_query_params) {
        const newDbcSearchParams = {};
        for (const param of query.qry_query_params) {
          const column =
            param.data_source && param.data_source.column
              ? param.data_source.column
              : null;

          if (!param.value) {
            if (column) {
              param.value =
                param.options && param.options.length
                  ? param.options[param.options.length - 1][column]
                  : 0;
            } else {
              param.value = coreSearchParams[param.key];
            }
          }
          newDbcSearchParams[param.key] = param.value;
        }

        setDbcSearchParams((prevParams) => ({
          ...prevParams,
          ...newDbcSearchParams,
        }));
      }

      // Set URL params
      for (const [key, value] of allSearchParams.entries()) {
        const parts = key.split("_");
        const lastPart = parts[parts.length - 1] || null;

        if (lastPart !== `dbc${dashboardComponent.dbc_id}`) continue;

        let paramKey = parts.slice(0, -1).join("_");
        setDbcSearchParams((prevParams) => ({
          ...prevParams,
          [paramKey]: value,
        }));
      }
    });

    return () => {
      Emitter.off("HISTORY_PUSHSTATE");
    };
  }, []);

  // //Set default params
  // if (query && query.qry_query_params) {
  //   for (var param of query.qry_query_params) {
  //     const column =
  //       param.data_source && param.data_source.column
  //         ? param.data_source.column
  //         : null;

  //     if (!param.value) {
  //       if (column) {
  //         param.value =
  //           param.options && param.options.length
  //             ? param.options[param.options.length - 1][column]
  //             : 0;
  //       } else {
  //         param.value = coreSearchParams[param.key];
  //       }
  //     }
  //     dbcSearchParams[param.key] = param.value;
  //   }
  // }

  // //Set URL params
  // for (const [key, value] of allSearchParams.entries()) {
  //   const parts = key.split("_");
  //   const lastPart = parts[parts.length - 1] || null;

  //   if (lastPart !== `dbc${dashboardComponent.dbc_id}`) continue;

  //   let param_key = parts.slice(0, -1).join("_");
  //   dbcSearchParams[param_key] = value;

  //   // if (lastPart !== `dbc${dashboardComponent.dbc_id}`) {
  //   //   param_key = parts.join("_");
  //   //   coreSearchParams[param_key] = value;
  //   // } else {
  //   //   param_key = parts.slice(0, -1).join("_");
  //   //   dbcSearchParams[param_key] = value;
  //   // }
  // }

  //let processData;

  // let url = null;
  // if (dashboardComponent.dbc_data_source) {
  //   if (dashboardComponent.dbc_data_source.url) {
  //     url = dashboardComponent.dbc_data_source.url;
  //   } else if (
  //     dashboardComponent.dbc_data_source.provider &&
  //     dashboardComponent.dbc_data_source.provider === "dune" &&
  //     dashboardComponent.dbc_data_source.query_id
  //   ) {
  //     url = `${config.dune_api.url}/query/${dashboardComponent.dbc_data_source.query_id}`;
  //   } else if (dashboardComponent.dbc_data_source.query_id) {
  //     url = `${config.api.url}/query/${dashboardComponent.dbc_data_source.query_id}`;
  //   }
  // }

  //Data Fetch
  const dataQuery = useQuery({
    queryKey: [
      `dataQuery_${dashboardComponent.dbc_id}`,
      dbcSearchParams,
      dataCache,
    ],
    queryFn: async ({ queryKey }) => {
      //const [_key, { dataCache }] = queryKey
      let method = "GET";

      let queryParams = { ...dbcSearchParams };
      // if (processData && processData.job_id) {
      //   queryParams.job_id = processData.job_id;
      // }

      let url = null;
      if (dashboardComponent.dbc_data_source.url) {
        url = dashboardComponent.dbc_data_source.url;
      } else if (dashboardComponent.dbc_data_source.query_id) {
        url = `${config.api.url}/query/${dashboardComponent.dbc_data_source.query_id}`;
      }

      // else if (
      //   dashboardComponent.dbc_data_source.provider &&
      //   dashboardComponent.dbc_data_source.provider === "dune"
      // ) {
      //   url = `${config.dune_api.url}/query/${dashboardComponent.dbc_data_source.query_id}/results`;
      //   // if (processData && processData.state === "QUERY_STATE_COMPLETED") {
      //   //   url = `${config.dune_api.url}/execution/${processData.execution_id}/results`;
      //   // } else if (
      //   //   processData &&
      //   //   (processData.state === "QUERY_STATE_PENDING" ||
      //   //     processData.state === "QUERY_STATE_EXECUTING")
      //   // ) {
      //   //   url = `${config.dune_api.url}/execution/${processData.execution_id}/status`;
      //   // } else {
      //   //   method = "POST";
      //   //   url = `${config.dune_api.url}/query/${dashboardComponent.dbc_data_source.query_id}/execute`;
      //   // }

      // }

      let headers = {
        "x-data-cache":
          dataQuery.dataCache === false || dataCache === false ? false : true,
      };

      // if (
      //   dashboardComponent.dbc_data_source.provider &&
      //   dashboardComponent.dbc_data_source.provider === "dune"
      // ) {
      //   headers["x-dune-api-key"] = config.dune_api.key;
      // }

      let fetchOptions = {
        method: method,
        headers: headers,
        cache: "no-cache",
        credentials: "include",
      };

      const response = await fetch(
        `${url}${
          Object.keys(queryParams).length
            ? "?" +
              new URLSearchParams({
                ...queryParams,
              })
            : ""
        }`,
        fetchOptions
      );

      // dataCache = dataQuery.dataCache = true; //Reset back
      dataQuery.dataCache = true;
      setDataCache(true);

      if (!response.ok) {
        throw new Error("Network response was not ok");
      }

      // (dashboardComponent.dbc_data_source.provider &&
      //   dashboardComponent.dbc_data_source.provider === "dune" &&
      //   (!processData ||
      //     (processData &&
      //       (processData.state === "QUERY_STATE_PENDING" ||
      //         processData.state === "QUERY_STATE_EXECUTING")))

      const responseData = await response.json();

      if (
        responseData.status === "PENDING" ||
        responseData.status === "PROCESSING"
      ) {
        //processData = await response.json();
        throw new Error("Wait for query data");
      }

      //processData = null; //Reset
      return responseData;
    },
    enabled: enabled ? true : false,
    retry: 15, // Number of retries before giving up
    retryDelay: (retryCount) => Math.min((retryCount + 1) * 2000, 30000), // Delay between retries (in milliseconds)
  });

  useEffect(() => {
    if (query.qry_options && query.qry_options.cache === "none") {
      //dataCache = dataQuery.dataCache = false;
      dataQuery.dataCache = false;
      setDataCache(false);
    } else {
      //dataCache = dataQuery.dataCache = true;
      dataQuery.dataCache = true;
      setDataCache(true);
    }

    // setTimeout(() => {
    //   if (dashboardComponent.dbc_data_source) setEnabled(true);
    // }, 1000);
  }, []);

  const handleVisibilitySensorChange = useCallback((isVisible) => {
    if (isVisible) {
      setTimeout(() => {
        if (dashboardComponent.dbc_data_source) setEnabled(true);
      }, 1000);
    }
  });

  useEffect(() => {
    Emitter.on("REFETCH_DASHBOARD", (newValue) => {      
      dataQuery.dataCache = false;            
      dataQuery.refetch();
    });

    return () => {
      Emitter.off("REFETCH_DASHBOARD");
    };
  }, [dataQuery]);

  // const formatData = () => {
  //   const options = dashboardComponent.dbc_component_options;
  //   const data = dataQuery.data;
  //   let _data;
  //   switch (component.cmp_type) {
  //     case "counter":
  //       const column = options.column;
  //       const row = options.row;

  //       //_data = data.data[row][Object.keys(data.data[row])[column]];
  //       _data = data.data[row][column];

  //       return _data;
  //     case "line-chart":
  //       const title = options.title;
  //       const X_column = options.X_column;
  //       const Y_column = options.Y_column;
  //       const X_column_labels = data.data.map((item) => item[X_column]);
  //       const Y_column_values = data.data.map((item) => item[Y_column]);

  //       _data = {
  //         labels: X_column_labels,
  //         datasets: [
  //           {
  //             label: title,
  //             data: Y_column_values,
  //             fill: false,
  //             borderColor: "rgba(75,192,192,1)",
  //           },
  //         ],
  //       };

  //       return _data;
  //     default:
  //       return;
  //   }
  // };

  const renderComponent = () => {
    // const options = dashboardComponent.dbc_component_options;
    // const extraOptions = dashboardComponent.dbc_component_extra_options;
    //const _data = formatData();
    switch (component?.cmp_type) {
      case "counter":
        return (
          <Counter
            {...props}
            dataQuery={dataQuery}
            dbcSearchParams={dbcSearchParams}
            prevDbcSearchParams={prevDbcSearchParams}
            //coreSearchParams={coreSearchParams}
            handleVisibilitySensorChange={handleVisibilitySensorChange}
          />
        );
      // case "line-chart":
      //   return <LineChart {...props} dataQuery={dataQuery} />;
      case "chartjs-chart":
        return (
          <ChartjsChart
            {...props}
            dataQuery={dataQuery}
            dbcSearchParams={dbcSearchParams}
            prevDbcSearchParams={prevDbcSearchParams}
            //coreSearchParams={coreSearchParams}
            handleVisibilitySensorChange={handleVisibilitySensorChange}
          />
        );
      case "table":
        return (
          <Table
            {...props}
            dataQuery={dataQuery}
            dbcSearchParams={dbcSearchParams}
            prevDbcSearchParams={prevDbcSearchParams}
            //coreSearchParams={coreSearchParams}
            handleVisibilitySensorChange={handleVisibilitySensorChange}
          />
        );
      case "visx-heatmap":
        return (
          <VisxHeatmap
            {...props}
            dataQuery={dataQuery}
            dbcSearchParams={dbcSearchParams}
            prevDbcSearchParams={prevDbcSearchParams}
            //coreSearchParams={coreSearchParams}
            handleVisibilitySensorChange={handleVisibilitySensorChange}
          />
        );
      case "visx-colormap":
        return (
          <VisxColormap
            {...props}
            dataQuery={dataQuery}
            dbcSearchParams={dbcSearchParams}
            prevDbcSearchParams={prevDbcSearchParams}
            //coreSearchParams={coreSearchParams}
            handleVisibilitySensorChange={handleVisibilitySensorChange}
          />
        );
      case "heatmap":
        return (
          <Heatmap
            {...props}
            dataQuery={dataQuery}
            dbcSearchParams={dbcSearchParams}
            prevDbcSearchParams={prevDbcSearchParams}
            //coreSearchParams={coreSearchParams}
            handleVisibilitySensorChange={handleVisibilitySensorChange}
          />
        );
      case "image-viewer":
        return (
          <ImageViewer
            {...props}
            dataQuery={dataQuery}
            dbcSearchParams={dbcSearchParams}
            prevDbcSearchParams={prevDbcSearchParams}
            //coreSearchParams={coreSearchParams}
            handleVisibilitySensorChange={handleVisibilitySensorChange}
          />
        );
      case "text":
        return <Text {...props} />;
      case "embed-iframe":
        return <EmbedIframe {...props} />;
      default:
        return <div>Unknown component type: {component?.cmp_type}</div>;
    }
  };

  // if (dataQuery.isLoading) {
  //   return <div>Loading...</div>;
  // }

  return <>{renderComponent()}</>;
}

export default DashboardComponent;
