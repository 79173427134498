import { Link, useNavigate, useSearchParams } from "react-router-dom";
import config from "../../../../app/config";
import { useContext, useEffect, useState } from "react";
import AuthService from "../../../../app/services/auth";
import { UserContext } from "../../../../app/contexts/user-context";

const Login = () => {
  const [searchParams, setSearchParams] = useSearchParams();
  const [flashMessage, setFlashMessage] = useState(null);
  const {user,login} = useContext(UserContext);
  const navigate = useNavigate();

  useEffect(() => {
    if (searchParams.get("token")) {
      var token = searchParams.get("token");
      AuthService.getUser(token).then((response) => {
        login(response.user);
        navigate("/dashboards");
        // dispatch(setUser(response.user));
        // if (history.location.state && history.location.state.from) {
        //   history.push(history.location.state.from);
        // } else {
        //   history.push("/admin/dashboard");
        // }
      });
    }

    if (searchParams.get("code")) {
      var code = searchParams.get("code");

      if (code === "USER_NOT_FOUND") {
        setFlashMessage({ type: "danger", message: "User not found" });
      }else if (code === "USER_INACTIVE") {
        setFlashMessage({ type: "danger", message: "Inactive user" });
      }
    }
  }, []);

  const socialLogin = (type) => {
    window.open(`${config.api.url}/auth/login/${type}`, "_self");
  };

  return (
    <>
      <div className="row justify-content-center">
        <div className="col-md-8 col-lg-6 col-xl-4">
          <div className="card">
            <div className="card-body p-4">
              <div className="text-center w-75 m-auto">
                <div className="auth-logo mb-4">
                  <Link to="/admin" className="logo logo-dark text-center">
                    <span className="logo-lg">
                      <span className="logo-lg-text-light">Yeomen.ai</span>
                    </span>
                  </Link>
                  <Link to="/admin" className="logo logo-light text-center">
                    <span className="logo-lg">
                      <span className="logo-lg-text-light">Yeomen.ai</span>
                    </span>
                  </Link>
                </div>
              </div>
               {flashMessage ? (
              <>
                <div className={`alert alert-${flashMessage.type}`}>
                  {flashMessage.message}
                </div>
              </>
            ) : null} 

              <div className="d-grid mb-0 text-center">
                <button
                  className="btn btn-lg btn-block btn-danger text-uppercase"
                  type="button"
                  onClick={() => socialLogin("google")}
                >
                  <i class="fab fa-google me-2"></i>
                  Sign in with Google
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Login;
