import {
  Link,
  useLocation,
  useParams,
  useSearchParams,
} from "react-router-dom";
import { useQuery } from "@tanstack/react-query";
import DashboardComponent from "../../../app/components/dashboard-component";

import "gridstack/dist/gridstack.min.css";
import { GridStack } from "gridstack";
import React, {
  createRef,
  useCallback,
  useContext,
  useEffect,
  useLayoutEffect,
  useRef,
} from "react";
import config from "../../../app/config";
import PageBreadcrumb from "../../layouts/components/page-breadcrumb";
import { BreadcrumbsItem } from "react-breadcrumbs-dynamic";
import Skeleton from "react-loading-skeleton";
import { useState } from "react";
import { UserContext } from "../../../app/contexts/user-context";
import { isMobile } from "react-device-detect";
// import VisxHeatmap from "../../../app/components/visx-heatmap";
import { Helmet } from "react-helmet";
import moment from "moment";
import SampleChart from "../../../app/components/sample-chart";
import WorldContract from "../../../app/components/world-contract";
import _ from "lodash";
import DashboardSettings from "../../../app/components/modals/dashboard/dashboard-settings";
import Emitter from "../../../app/services/emitter";
import ApiService from "../../../app/services/api";
import DashboardSearchparams from "../../../app/components/modals/dashboard/dashboard-searchparams";
import RelatedDashboards from "./components/related-dashboards";
import { usePrevious } from "@uidotdev/usehooks";
import * as primodiumMethods from "../../../app/helpers/projects/primodium";
import DateRangePicker from "react-bootstrap-daterangepicker";

const Dashboard = () => {
  const location = useLocation();
  const prevLocation = usePrevious(location);
  const { user } = useContext(UserContext);
  // const { slug } = useParams();
  const { "*": slug } = useParams();
  const gridContainerRef = useRef(null);
  const gridRef = useRef(null);
  const gridItemsRefs = useRef({});
  const [searchParams, setSearchParams] = useSearchParams();
  const [dashboardSearchParams, setDashboardSearchParams] = useState({});
  const [dashboardQueryFetchEnabled, setDashboardQueryFetchEnabled] =
    useState(false);

  // Memoized function to prevent unnecessary re-renders of ChildComponent
  // const handleSetSearchParams = useCallback(() => {
  //   console.log('Button clicked');
  //   // Call setSearchParams from the child
  //  // setSearchParams(/* your updated search params */);
  // }, [setSearchParams]);

  useEffect(() => {}, [slug]);

  useEffect(() => {
    Emitter.on("SAVE_DASHBOARD_SETTINGS_SUCCESS", (newValue) => {
      dashboardQuery.refetch();
    });

    return () => {
      Emitter.off("SAVE_DASHBOARD_SETTINGS_SUCCESS");
    };
  });

  useEffect(() => {
    Emitter.on("REFETCH_DASHBOARD", (newValue) => {
      dashboardQuery.refetch();
    });

    return () => {
      Emitter.off("REFETCH_DASHBOARD");
    };
  }, []);

  const getCoreSearchParams = () => {
    const allSearchParams = searchParams;

    const _coreSearchParams = {};
    for (const [key, value] of allSearchParams.entries()) {
      const parts = key.split("_");
      const lastPart = parts[parts.length - 1] || null;

      let param_key;
      if (!/^dbc\d+$/.test(lastPart)) {
        param_key = parts.join("_");
        _coreSearchParams[param_key] = value.toLowerCase();
      }
    }

    return _coreSearchParams;
  };

  const [coreSearchParams, setCoreSearchParams] = useState(null);
  useEffect(() => {
    // Get the existing query parameters
    //const allSearchParams = new URLSearchParams(location.search);
    // const allSearchParams = searchParams;

    // const _coreSearchParams = {};
    // for (const [key, value] of allSearchParams.entries()) {
    //   const parts = key.split("_");
    //   const lastPart = parts[parts.length - 1] || null;

    //   let param_key;
    //   if (!/^dbc\d+$/.test(lastPart)) {
    //     param_key = parts.join("_");
    //     _coreSearchParams[param_key] = value;
    //   }
    // }
    //if (dashboardQueryFetchEnabled) return;

    const _coreSearchParams = getCoreSearchParams();

    setCoreSearchParams(_coreSearchParams);
    setDashboardSearchParams(_coreSearchParams);
    // if (Object.keys(_coreSearchParams).length) {
    //   localStorage.setItem(
    //     `dashboard:${slug}`,
    //     JSON.stringify(_coreSearchParams)
    //   );
    // }

    setDashboardQueryFetchEnabled(true);
  }, [slug, searchParams]);

  const componentsQuery = useQuery({
    queryKey: ["components"],
    queryFn: async () => {
      try {
        const response = await fetch(`${config.api.url}/components`);
        if (!response.ok) {
          throw new Error("Network response was not ok");
        }
        return response.json();
      } catch (err) {
        // Handle or log the error, if needed
        console.error("Error fetching:", err);
        return null;
      }
    },
  });

  const dashboardQuery = useQuery({
    queryKey: [`dashboard-${slug}`, slug, searchParams],
    queryFn: async () => {
      try {
        const _coreSearchParams = getCoreSearchParams();

        let queryParams = { ..._coreSearchParams };

        const response = await fetch(
          `${config.api.url}/dashboard/${slug}${
            Object.keys(queryParams).length
              ? "?" +
                new URLSearchParams({
                  ...queryParams,
                })
              : ""
          }`,
          {
            credentials: "include",
          }
        );
        if (!response.ok) {
          throw new Error("Network response was not ok");
        }
        setDashboardQueryFetchEnabled(false);
        return response.json();
      } catch (err) {
        // Handle or log the error, if needed
        console.error("Error fetching:", err);
        return null;
      }
    },
    //enabled: coreSearchParams ? true : false,
    enabled: dashboardQueryFetchEnabled,
  });

  useEffect(() => {
    if (dashboardQuery.isLoading) return;
    if (!dashboardQuery.data) return;

    if (!dashboardQuery.data?.dashboardSearchParamsExists) {
      // const newDashboardSearchParams = {};
      // for (const dashboardSearchParam of dashboardQuery.data?.dashboard
      //   .dbd_options.search_params) {
      //     newDashboardSearchParams[dashboardSearchParam.key] = "";
      // }
      //setDashboardSearchParams({"demo":'ddd'});

      window.$(`#dashboard-searchparams-modal`).modal("show");
      return;
    } else {
      window.$(`#dashboard-searchparams-modal`).modal("hide");
    }

    //if (!dashboardQuery.data?.dashboardComponents) return;

    //  if(gridRef.current){
    //    gridRef.current.removeAll();
    //  }

    // gridRef.current = GridStack.init({
    //   container: gridContainerRef.current,
    //   cellHeight: 100,
    //   staticGrid: user.accessToken ? false : true,
    //   columnOpts: {
    //     breakpointForWindow: true, // test window vs grid size
    //     breakpoints: [{ w: 1000, c: 1 }],
    //   },
    //   float: false,
    // });
    //console.log(gridRef.current.engine.nodes,dashboardQuery.data?.dashboardComponents)
    //  return () => {

    //    if (gridRef.current) {
    //     console.log('remove items')
    //     console.log(gridRef.current)
    //     gridRef.current.removeAll(true);
    //   }
    //  };

    // gridItemsRefs.current = {};

    // if (
    //   Object.keys(gridItemsRefs.current).length !==
    //   dashboardQuery.data?.dashboardComponents.length
    // ) {
    //   dashboardQuery.data?.dashboardComponents.forEach(
    //     (dashboardComponent) => {
    //       gridItemsRefs.current[dashboardComponent.dbc_id] =
    //         gridItemsRefs.current[dashboardComponent.dbc_id] || createRef();
    //     }
    //   );
    // }

    if (!gridRef.current) {
      gridRef.current = GridStack.init({
        container: gridContainerRef.current,
        cellHeight: 102,
        staticGrid: user && user.role === "super_admin" ? false : true,
        columnOpts: {
          breakpointForWindow: true, // test window vs grid size
          breakpoints: [{ w: 1000, c: 1 }],
        },
        float: false,
      });
    } else {
      // gridItemsRefs.current = {};

      const layout = dashboardQuery.data?.dashboardComponents.map(
        (dashboardComponent) => {
          const displayOptions = dashboardComponent.dbc_display_options;

          const gridstackNode = gridItemsRefs.current[dashboardComponent.dbc_id]
            ?.current?.gridstackNode || {
            id: `item-${dashboardComponent.dbc_id}`,
            x: displayOptions.x, // Assuming displayOptions is defined somewhere
            y: displayOptions.y,
            w: displayOptions.w,
            el: gridItemsRefs.current[dashboardComponent.dbc_id]?.current,
          };

          return gridstackNode;
        }
      );

      //gridRef.current._ignoreCB = true; // hack: ignore added/removed since we're the one doing the update
      gridRef.current.load(layout);
      //delete gridRef.current._ignoreCB;
    }

    return () => {
      // Clear all items from the GridStack
      gridRef.current.removeAll(true);
    };
  }, [dashboardQuery]);

  // if (dashboardQuery.isLoading) {s
  //   return <div>Loading...</div>;
  // }

  const handleSaveDashboard = async () => {
    const grid = gridRef.current;
    let gridData = grid.save();

    gridData.map((data) => {
      delete data["content"];
      return data; // Return the modified object
    });

    try {
      const response = await fetch(
        `${config.api.url}/dashboard/${slug}/components`,
        {
          method: "PUT",
          headers: {
            // Accept: "application/json",
            "Content-Type": "application/json",
            //"x-access-token": user?.access_token || null,
          },
          credentials: "include",
          body: JSON.stringify({ gridData: gridData }),
        }
      );

      if (response.status === "error") {
        throw new Error(response.message);
      }

      window.$.toast({
        heading: "Success",
        text: "Save dashboard success",
        position: "top-right",
        icon: "success",
      });
    } catch (err) {
      console.log(err);
      window.$.toast({
        heading: "Error",
        text: "Save dashboard failed",
        position: "top-right",
        icon: "error",
      });
    }
  };

  const handleDuplicateDashboard = async () => {
    
    try {
      const response = await fetch(
        `${config.api.url}/dashboard/${slug}/duplicate`,
        {
          method: "GET",
          headers: {
            // Accept: "application/json",
            "Content-Type": "application/json",
            //"x-access-token": user?.access_token || null,
          },
          credentials: "include"         
        }
      );

      if (response.status === "error") {
        throw new Error(response.message);
      }

      window.$.toast({
        heading: "Success",
        text: "Duplicate dashboard success",
        position: "top-right",
        icon: "success",
      });
    } catch (err) {
      console.log(err);
      window.$.toast({
        heading: "Error",
        text: "Duplicate dashboard failed",
        position: "top-right",
        icon: "error",
      });
    }
  };

  const handleRefetch = () => {
    Emitter.emit("REFETCH_DASHBOARD");
  };

  const getDashboardLink = (dashboard) => {
    const slug = dashboard.dbd_slug;

    const dashboardSearchParams = JSON.parse(
      localStorage.getItem(`dashboard:${slug}`)
    );

    let queryParams = { ...dashboardSearchParams };

    const link = `/dashboard/${slug}${
      Object.keys(queryParams).length
        ? "?" +
          new URLSearchParams({
            ...queryParams,
          })
        : ""
    }`;

    return link;
  };

  // if (dashboardQuery.isLoading) {
  //   return (
  //     <div className="row mt-4">
  //       <div className="col-md-12">
  //         <div className="card">
  //           <div className="card-body">
  //             <Skeleton />
  //           </div>
  //         </div>
  //       </div>
  //     </div>
  //   );
  // }

  const generateDashboardComponents = async () => {
    try {
      const response = await ApiService.post(`dashboard/${slug}/generate`);

      const responseData = await response.json();

      if (responseData.status === "error") {
        throw new Error(response.message);
      }

      window.$.toast({
        heading: "Success",
        text: "Generate success",
        position: "top-right",
        icon: "success",
      });

      dashboardQuery.refetch();
    } catch (err) {
      window.$.toast({
        heading: "Error",
        text: "Generate failed",
        position: "top-right",
        icon: "error",
      });
    }
  };

  const [dates, setDates] = useState({
    startDate: moment().subtract(3, "months"),
    endDate: moment(),
  });

  return (
    <>
      <Helmet>
        <title>
          {dashboardQuery.data?.mainDashboard.dbd_name || slug} - Analytics
          dashboard by Yeomen.ai
        </title>
      </Helmet>
      <BreadcrumbsItem to="/dashboards">Dashboards</BreadcrumbsItem>
      <BreadcrumbsItem
        to={`/dashboard/${dashboardQuery.data?.mainDashboard.dbd_slug}`}
      >
        {dashboardQuery.data?.mainDashboard.dbd_name}
      </BreadcrumbsItem>
      <BreadcrumbsItem
        to={`/dashboard/${dashboardQuery.data?.dashboard.dbd_slug}`}
      >
        {dashboardQuery.data?.dashboard.dbd_name}
      </BreadcrumbsItem>

      {dashboardQuery.isLoading ? (
        <PageBreadcrumb title={`Dashboard`} />
      ) : (
        <PageBreadcrumb
          title={`Dashboard: ${dashboardQuery.data?.mainDashboard.dbd_name}${
            dashboardQuery.data?.dashboard.dbd_id !==
            dashboardQuery.data?.mainDashboard.dbd_id
              ? " / " + dashboardQuery.data?.dashboard.dbd_name
              : ""
          }`}
        />
      )}

      {user && user.role === "super_admin" ? (
        <div className="row">
          <div className="col-sm-12">
            <div className="float-sm-end">
              <form className="d-flex align-items-center flex-wrap">
                <button
                  type="button"
                  className="btn mb-2 ms-2 mb-sm-0 btn-sm btn-success"
                  data-bs-toggle="modal"
                  data-bs-target="#dashboard-settings-modal"
                >
                  Edit Dasboard
                </button>
                <button
                  type="button"
                  className="btn mb-2 ms-2 mb-sm-0 btn-sm btn-success"
                  data-bs-toggle="modal"
                  data-bs-target="#dashboard-abi-modal"
                >
                  Contract
                </button>
                <button
                  type="button"
                  className="btn mb-2 ms-2 mb-sm-0 btn-sm btn-success"
                  onClick={handleSaveDashboard}
                >
                  Save Dashboard
                </button>
                <button
                  type="button"
                  className="btn mb-2 ms-2 mb-sm-0 btn-sm btn-success"
                  onClick={handleDuplicateDashboard}
                >
                  Duplicate Dashboard
                </button>
                <button
                  type="button"
                  className="btn mb-2 ms-2 mb-sm-0 btn-sm btn-success"
                  onClick={handleRefetch}
                >
                  Refetch
                </button>               
              </form>
            </div>
          </div>
        </div>
      ) : null}

      <div className="row mt-4">
        <div className="col-sm-12">
          <div className="card">
            <div className="card-body">
              <div className="d-flex align-items-start">
                <div className="avatar-xxl me-3">
                  <div className="avatar-title bg-light ">
                    {dashboardQuery.isLoading ? (
                      <Skeleton />
                    ) : (
                      <img
                        src={`${dashboardQuery.data?.mainDashboard.dbd_options.logo}`}
                        alt={`${dashboardQuery.data?.mainDashboard.dbd_name}`}
                        className="avatar-xxl"
                        style={{ borderRadius: "16px" }}
                      />
                    )}
                  </div>
                </div>

                <div className="flex-1">
                  <h4 className="my-1">
                    {dashboardQuery.isLoading ? (
                      <Skeleton />
                    ) : (
                      dashboardQuery.data?.mainDashboard.dbd_name
                    )}
                  </h4>
                  <div className="button-list">
                    {dashboardQuery.isLoading ? (
                      <Skeleton />
                    ) : (
                      dashboardQuery.data?.mainDashboard.dbd_options.tags.map(
                        (tag, index) => {
                          return (
                            <button
                              key={index}
                              type="button"
                              className="btn btn-light btn-xs waves-effect waves-light"
                            >
                              {/* <Link to={`/dashboards?tags=${tag}`}>{tag}</Link> */}
                              <Link to={`/${tag}`}>{tag}</Link>
                            </button>
                          );
                        }
                      )
                    )}
                  </div>
                  <div className="mt-2">
                    <p className="text-muted mb-2">
                      Creator:{" "}
                      {dashboardQuery.isLoading ? (
                        <Skeleton />
                      ) : (
                        dashboardQuery.data?.mainDashboard.dbd_options
                          .creator || "N/A"
                      )}
                    </p>
                    <p className="text-muted  mb-2">
                      Website:&nbsp;
                      {dashboardQuery.isLoading ? (
                        <Skeleton />
                      ) : dashboardQuery.data?.mainDashboard.dbd_options
                          .website ? (
                        <a
                          href={
                            dashboardQuery.data.mainDashboard.dbd_options
                              .website
                          }
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          {
                            dashboardQuery.data.mainDashboard.dbd_options
                              .website
                          }
                        </a>
                      ) : (
                        "N/A"
                      )}
                    </p>

                    {/* <p className="text-muted mt-2">
                    Created{" "}
                    {dashboardQuery.isLoading ? (
                      <Skeleton />
                    ) : (
                      moment(
                        dashboardQuery.data?.mainDashboard.dbd_created_at
                      ).fromNow()
                    )}
                  </p> */}
                    <p className=" mb-2">
                      {dashboardQuery.isLoading ? (
                        <Skeleton />
                      ) : (
                        dashboardQuery.data?.mainDashboard.dbd_options
                          .description
                      )}
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* <div className="row mb-2">
        <div className="col-sm-12">
          <div className="float-sm-end">
            <form className="d-flex align-items-center flex-wrap">
              <div className="me-2">
                <label className="visually-hidden">Date Filter</label>
                <DateRangePicker
                  initialSettings={{
                    format: "DD/MM/YYYY",
                    startDate: dates.startDate,
                    endDate: dates.endDate,
                    showDropdowns: true,
                    showWeekNumbers: true,
                    timePicker: false,
                    timePickerIncrement: 1,
                    timePicker12Hour: true,
                    ranges: {
                      Today: [moment(), moment()],
                      "Last 24 Hours": [
                        moment().subtract(24, "hours"),
                        moment(),
                      ],
                      Yesterday: [
                        moment().subtract(1, "days"),
                        moment().subtract(1, "days"),
                      ],
                      "Last 7 Days": [
                        moment().subtract(7, "days"),
                        moment().subtract(1, "days"),
                      ],
                      "Last 30 Days": [
                        moment().subtract(30, "days"),
                        moment().subtract(1, "days"),
                      ],
                      "This Month": [
                        moment().startOf("month"),
                        moment().subtract(1, "days"),
                      ],
                      "Last Month": [
                        moment().subtract(1, "month").startOf("month"),
                        moment().subtract(1, "month").endOf("month"),
                      ],
                      "Last 3 Months": [
                        moment().subtract(3, "month"),
                        moment().subtract(1, "days"),
                      ],
                      "Last 6 Months": [
                        moment().subtract(6, "month"),
                        moment().subtract(1, "days"),
                      ],
                      "All time": [
                        moment("2020-07-01"),
                        moment().subtract(1, "days"),
                      ],
                    },
                    opens: "left",
                    drops: "down",
                    buttonClasses: ["btn", "btn-sm"],
                    applyClass: "btn-success",
                    cancelClass: "btn-secondary",
                    separator: " to ",
                    locale: {
                      format: "YYYY/MM/DD",
                      applyLabel: "Submit",
                      cancelLabel: "Cancel",
                      fromLabel: "From",
                      toLabel: "To",
                      customRangeLabel: "Custom",
                      daysOfWeek: ["Su", "Mo", "Tu", "We", "Th", "Fr", "Sa"],
                      monthNames: [
                        "January",
                        "February",
                        "March",
                        "April",
                        "May",
                        "June",
                        "July",
                        "August",
                        "September",
                        "October",
                        "November",
                        "December",
                      ],
                      firstDay: 1,
                    },
                  }}
                  // onCallback={handleDateRangeCallback}
                >
                  <input type="text" className="form-control" />
                </DateRangePicker>
              </div>
              <button
                type="button"
                className="btn btn-success mb-sm-0"
                // onClick={handleBtnClick}
              >
                Search
              </button>
            </form>
          </div>
        </div>
      </div> */}

      {dashboardQuery.data?.mainDashboardChilds.length ? (
        <div className="row">
          <div className="col-md-12">
            <div className="card">
              <div className="card-body">
                <div className="row">
                  <div className="col-md-6">
                    {dashboardQuery.isLoading ? (
                      <Skeleton />
                    ) : (
                      <ul className="nav nav-pills navtab-bg nav-justified">
                        <li className="nav-item p-2">
                          <Link
                            to={`/dashboard/${dashboardQuery.data?.mainDashboard.dbd_slug}`}
                            className={`nav-link ${
                              dashboardQuery.data?.mainDashboard.dbd_id ===
                              dashboardQuery.data?.dashboard.dbd_id
                                ? "active"
                                : ""
                            }`}
                          >
                            <span className="d-inline-block d-sm-none">
                              <i className="mdi mdi-home-variant" />
                            </span>
                            <span className="d-none d-sm-inline-block">
                              Home
                            </span>
                          </Link>
                        </li>
                        {dashboardQuery.data?.mainDashboardChilds.map(
                          (mainDashboardChild, index) => {
                            return (
                              <li className="nav-item p-2" key={index}>
                                <Link
                                  //to={`/dashboard/${mainDashboardChild.dbd_slug}`}
                                  to={getDashboardLink(mainDashboardChild)}
                                  className={`nav-link ${
                                    mainDashboardChild.dbd_id ===
                                    dashboardQuery.data?.dashboard.dbd_id
                                      ? "active"
                                      : ""
                                  }`}
                                >
                                  <span className="d-inline-block d-sm-none">
                                    <i
                                      className={`mdi ${mainDashboardChild.dbd_options.icon_class}`}
                                    />
                                  </span>
                                  <span className="d-none d-sm-inline-block">
                                    {mainDashboardChild.dbd_name}
                                  </span>
                                </Link>
                              </li>
                            );
                          }
                        )}
                      </ul>
                    )}
                  </div>
                </div>
                {Object.keys(dashboardSearchParams).length ? (
                  <>
                    {dashboardSearchParams &&
                      Object.keys(dashboardSearchParams).map(
                        (paramKey, index) => (
                          <React.Fragment key={index}>
                            <span>
                              <strong>{paramKey}:</strong>{" "}
                              {dashboardSearchParams[paramKey]}
                            </span>
                          </React.Fragment>
                        )
                      )}

                    {/* <button
                    type="button"
                    className="btn btn-primary waves-effect waves-light ms-2"
                    onClick={() => {
                      window.$(`#dashboardSearchParamsModal`).modal("show");
                    }}
                  >
                    <i className="fas fa-edit" />
                  </button> */}

                    <i
                      className="fas fa-edit text-primary ms-2"
                      style={{ cursor: "pointer" }}
                      onClick={() => {
                        window.$(`#dashboard-searchparams-modal`).modal("show");
                      }}
                    />

                    {/* <button
                    type="button"
                    className="btn btn-info btn-sm waves-effect waves-light ms-2"
                    onClick={() => {
                      window.$(`#dashboardSearchParamsModal`).modal("show");
                    }}
                  >
                    Edit Search
                  </button> */}
                  </>
                ) : null}
              </div>
            </div>
          </div>
        </div>
      ) : null}

      <div className="row">
        <div className="col-sm-12">
          <div className="grid-container center">
            <div
              className="grid-stack"
              //id={`dashboard-grid`}
              ref={gridContainerRef}
            >
              {dashboardQuery.data?.dashboardComponents &&
                dashboardQuery.data?.dashboardComponents.map(
                  (dashboardComponent, index) => {
                    const displayOptions =
                      dashboardComponent.dbc_display_options;

                    const dashboard = dashboardQuery.data?.dashboard;
                    const mainDashboard = dashboardQuery.data?.mainDashboard;

                    const component = componentsQuery.data?.components.find(
                      (component) =>
                        component.cmp_id === dashboardComponent.dbc_component_id
                    );

                    const query = dashboardQuery.data?.queries.find(
                      (query) =>
                        dashboardComponent.dbc_data_source &&
                        query.qry_id ===
                          dashboardComponent.dbc_data_source.query_id
                    );
                    gridItemsRefs.current[dashboardComponent.dbc_id] =
                      gridItemsRefs.current[dashboardComponent.dbc_id] ||
                      createRef();

                    return (
                      <div
                        ref={gridItemsRefs.current[dashboardComponent.dbc_id]}
                        className="grid-stack-item"
                        key={index}
                        gs-id={`item-${dashboardComponent.dbc_id}`}
                        gs-x={displayOptions.x}
                        gs-y={displayOptions.y}
                        gs-w={displayOptions.w}
                        //gs-h={dispayOptions.h}
                        gs-h={
                          !isMobile
                            ? displayOptions.h
                            : Math.min(displayOptions.h, 6)
                        }
                      >
                        <div className="grid-stack-item-content">
                          <DashboardComponent
                            key={index}
                            component={component}
                            dashboardComponent={dashboardComponent}
                            query={query}
                            dashboard={dashboard}
                            mainDashboard={mainDashboard}
                            coreSearchParams={coreSearchParams}
                          />
                        </div>
                      </div>
                    );
                  }
                )}
            </div>

            {dashboardQuery.isLoading ? <Skeleton /> : null}

            {user && user.role === "super_admin" && dashboardQuery.data?.dashboardComponents?.length === 0 &&
            dashboardQuery.data?.dashboard?.parent_id === null ? (
              <>
                <div className="card">
                  <div className="card-body text-center">
                    <p>Auto generate standard dashboard</p>
                    <button
                      type="button"
                      className="btn mb-2 ms-2 mb-sm-0 btn-sm btn-success"
                      onClick={generateDashboardComponents}
                    >
                      Generate
                    </button>
                  </div>
                </div>
              </>
            ) : null}
          </div>
        </div>
      </div>

      <RelatedDashboards dashboardQuery={dashboardQuery} />

      {/* <div
        id="dashboardSearchParamsModal"
        className="modal fade"
        tabIndex={-1}
        role="dialog"
        aria-hidden="true"
        style={{ display: "none" }}
      >
        <div className="modal-dialog">
          <div className="modal-content">
            <div className="modal-header">
              <h4 className="modal-title">Search Preferences</h4>
              <button
                type="button"
                className="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
              />
            </div>
            <div className="modal-body p-4">
              {dashboardQuery.data?.dashboard.dbd_options.search_params &&
                dashboardQuery.data?.dashboard.dbd_options.search_params.map(
                  (searchParam, index) => {
                    return (
                      <div className="row" key={index}>
                        <div className="col-md-12">
                          <div className="mb-3">
                            <label className="form-label">
                              {searchParam.label}
                            </label>
                            <input
                              type="text"
                              className="form-control"
                              placeholder={`${searchParam.placeholder}`}
                              value={
                                dashboardSearchParams[searchParam.key] || ""
                              }
                              onChange={(e) => {
                                setDashboardSearchParams((prevState) => ({
                                  ...prevState,
                                  [searchParam.key]: e.target.value,
                                }));
                              }}
                            />
                          </div>
                          <div
                            class="form-text text-primary"
                            style={{ cursor: "pointer" }}
                            onClick={() => {
                              for (var searchParam of dashboardQuery.data
                                ?.dashboard.dbd_options.search_params) {
                                setDashboardSearchParams((prevState) => ({
                                  ...prevState,
                                  [searchParam.key]: searchParam.value,
                                }));
                              }
                            }}
                          >
                            Or use sample values instead
                          </div>
                        </div>
                      </div>
                    );
                  }
                )}
            </div>
            <div className="modal-footer">
              <button
                type="button"
                className="btn btn-secondary waves-effect"
                data-bs-dismiss="modal"
              >
                Close
              </button>
              <button
                type="button"
                className="btn btn-info waves-effect waves-light"
                onClick={() => {
                  //console.log(dashboardSearchParams);
                  //Store Cache
                  if (Object.keys(dashboardSearchParams).length) {
                    localStorage.setItem(
                      `dashboard:${slug}`,
                      JSON.stringify(dashboardSearchParams)
                    );
                  }

                  setSearchParams(dashboardSearchParams);
                }}
              >
                Search
              </button>
            </div>
          </div>
        </div>
      </div> */}

      <DashboardSearchparams
        dashboardQuery={dashboardQuery}
        dashboardSearchParams={dashboardSearchParams}
        setDashboardSearchParams={setDashboardSearchParams}
        setSearchParams={setSearchParams}
      />

      <DashboardSettings
        mainDashboard={dashboardQuery.data?.mainDashboard}
        mainDashboardIndexer={dashboardQuery.data?.mainDashboardIndexer}
        worldContract={dashboardQuery.data?.worldContract}
      />

      <WorldContract
        mainDashboard={dashboardQuery.data?.mainDashboard}
        worldContract={dashboardQuery.data?.worldContract}
      />
    </>
  );
};

export default Dashboard;
