import React, { useEffect, useRef, useState } from "react";
import _merge from "lodash/merge";
import TransformData from "../helpers/data-transformer";
import DbcFooter from "./dbc-footer";
import Skeleton from "react-loading-skeleton";
import { Link } from "react-router-dom";
import { isMobile } from "react-device-detect";

const Heatmap = (props) => {
  const [data, setData] = useState(null);
  const {
    component,
    dashboardComponent,
    dataQuery,
    query,
    dbcSearchParams,
    coreSearchParams,
  } = props;

  const cmpOptions = component.component_options;
  const dbcOptions = dashboardComponent.dbc_component_options;
  const displayOptions = dashboardComponent.dbc_display_options;
  //const dbcExtraOptions = dashboardComponent.dbc_component_extra_options;

  const options = {};

  const mergedOptions = _merge({}, options, cmpOptions, dbcOptions);

  const canvasRef = useRef(null);
  // Define the width and height of your image
  //const width = 1700; // example width
  // const height = 1700; //

  const calculateCanvasSize = (minX, maxX, minY, maxY) => {
    // Calculate range for x and y coordinates
    const xRange = maxX - minX;
    const yRange = maxY - minY;

    // Determine aspect ratio (e.g., square or based on ranges)
    const aspectRatio = 1; // For a square canvas

    // Determine canvas size based on aspect ratio and range
    let canvasWidth, canvasHeight;
    if (xRange > yRange) {
      canvasWidth = aspectRatio * xRange;
      canvasHeight = yRange;
    } else {
      canvasWidth = xRange;
      canvasHeight = aspectRatio * yRange;
    }

    return { width: canvasWidth, height: canvasHeight };
  };

  const minX = -850;
  const maxX = 850;
  const minY = -850;
  const maxY = 850;

  useEffect(() => {
    if (dataQuery.isLoading) return;

    const data = TransformData({
      component,
      dashboardComponent,
      data: dataQuery?.data,
    });

    setData(data?.data);
  }, [dataQuery]);

  useEffect(() => {
    if (!data) return;

    const { width, height } = calculateCanvasSize(minX, maxX, minY, maxY);

    const canvas = canvasRef.current;
    const ctx = canvas.getContext("2d");
    canvas.width = width;
    canvas.height = height;
    ctx.translate(width / 2, height / 2);

    //const minCount = Math.min(...data.map((item) => parseInt(item.count)));
    //const maxCount = Math.max(...data.map((item) => parseInt(item.count)));

    //const colorScale = getColorScale(minCount, maxCount);

    // for (let y = 0; y < height; y++) {
    //   for (let x = 0; x < width; x++) {
    //     // Generate a color based on x and y, or define your own
    //     const red = Math.floor(255 * (x / width));
    //     const green = Math.floor(255 * (y / height));
    //     const blue = 50; // example blue value

    //     // Set the color and draw the pixel
    //     ctx.fillStyle = `rgb(${red}, ${green}, ${blue})`;
    //     ctx.fillRect(x, y, 1, 1); // Draw a 1x1 pixel rectangle
    //   }
    // }

    const radius = 3;
    data.forEach((item) => {
      const x = parseInt(item.x);
      const y = parseInt(item.y);
      const total_battles = parseInt(item.total_battles);
      const total_units = parseInt(item.total_units);
      //const color = getColor(count, colorScale);
      ctx.beginPath();
      let color = getColor(total_battles,total_units);
      ctx.fillStyle = color;
      //ctx.fillStyle = total_count > 0 ? "red" : "grey";
      //ctx.fillRect(x, y, 5, 5); // Adjust size as needed
      if (total_battles > 0) {
        ctx.fillRect(x, y, 5, 5);
      } else {
        ctx.arc(x, y, radius, 0, Math.PI * 2);
      }

      ctx.fill();
    });

    //const randomData1 = data[Math.floor(Math.random() * data.length)];
    //const randomData2 = data[Math.floor(Math.random() * data.length)];

    // Calculate control point
    // const midPoint = {
    //     x: (randomData1.x + randomData2.x) / 2,
    //     y: (randomData1.y + randomData2.y) / 2
    //   };

    //   const offset = 50;
    //   const dx = randomData2.y - randomData1.y;
    //   const dy = -(randomData2.x - randomData1.x); // Negate one of the coordinates
    //   const length = Math.sqrt(dx * dx + dy * dy);
    //   const normalizedDx = dx / length;
    //   const normalizedDy = dy / length;

    //   const controlPoint = {
    //     x: midPoint.x + offset * normalizedDx,
    //     y: midPoint.y + offset * normalizedDy
    //   };

    // Draw a Bezier curve between the two coordinates
    //     ctx.beginPath();
    //     ctx.moveTo(randomData1.x, randomData1.y);
    //     ctx.quadraticCurveTo(
    //       (randomData1.x + randomData2.x) / 6,
    //       (randomData1.y + randomData2.y) / 6,
    //       randomData2.x,
    //       randomData2.y
    //     );
    //    // ctx.quadraticCurveTo(controlPoint.x, controlPoint.y, randomData2.x, randomData2.y);
    //     ctx.strokeStyle = 'white'; // Change stroke color as needed
    //     ctx.lineWidth = 1; // Change line width as needed
    //     ctx.stroke();

    // let start = { x: 50, y: 20 };
    // let cp1 = { x: 100, y: 30 };
    // let cp2 = { x: 150, y: 80 };
    // let end = { x: 250, y: 100 };

    // ctx.beginPath();
    // ctx.moveTo(start.x, start.y);
    // ctx.bezierCurveTo(cp1.x, cp1.y, cp2.x, cp2.y, end.x, end.y);
    // ctx.strokeStyle = "white";
    // ctx.lineWidth = 1;
    // ctx.stroke();
  }, [data]);

  // const getColorScale = (min, max) => {
  //   // Define your gradient scale here
  //   // You might want to use a library like d3-scale-chromatic for predefined scales
  //   // For simplicity, this example uses a basic linear scale
  //   const scale = (value) => {
  //     return Math.floor(((value - min) / (max - min)) * 255);
  //   };
  //   return scale;
  // };

  // const getColor = (count, scale) => {
  //   const r = scale(count);
  //   const g = 255 - r;
  //   return `rgb(${r}, ${g}, 0)`; // Adjust as needed
  // };

  function getColor(total_battles, total_units) {
    if (total_battles === 0 && total_units === 0) {
        return 'grey'; // total_battles = 0 and total_units = 0
    } else if (total_battles > 0 && total_units === 0) {
        return 'orange'; // total_battles > 0 and total_units = 0
    } else if (total_battles === 0 && total_units > 0) {
        return 'orange'; // total_battles = 0 and total_units > 0
    } else {
        return 'red'; // total_battles > 0 and total_units > 0
    }
}

  return (
    <div className={`card  mb-0 ${!isMobile ? "h-100" : "h-100"}`}>
      <div className="card-body">
        <Link
          to={`/dashboards/component/${dashboardComponent?.dbc_id}${
            coreSearchParams && Object.keys(coreSearchParams).length
              ? `?${new URLSearchParams(coreSearchParams).toString()}`
              : ""
          }`}
        >
          <h4 className="header-title">
            {mergedOptions.title ? `${mergedOptions.title}` : null}
          </h4>
        </Link>
        {mergedOptions.subtitle ? (
          <p className="card-subtitle mb-2 text-muted">
            {mergedOptions.subtitle}
          </p>
        ) : null}
        {data ? (
          <div
            className="text-center"
            style={{
              overflow: "auto",
              width: "100%",
              height: `${(displayOptions.h * 100) - 200}px`,
            }}
          >
            <canvas ref={canvasRef} />{" "}
          </div>
        ) : null}
        {dataQuery.isLoading ? (
          <div className="text-center">
            <Skeleton width={"25%"} />
          </div>
        ) : null}
      </div>
      <DbcFooter {...props} />
    </div>
  );

  // return <canvas ref={canvasRef} width={800} height={600} />;
};

export default Heatmap;
