import { isMobile } from "react-device-detect";
import { Link } from "react-router-dom";
import _merge from "lodash/merge";
import TransformData from "../helpers/data-transformer";
import {
  TransformWrapper,
  TransformComponent,
  useControls,
} from "react-zoom-pan-pinch";
import { useEffect, useState } from "react";
import DbcFooter from "./dbc-footer";
import VisibilitySensor from "react-visibility-sensor";
import Skeleton from "react-loading-skeleton";

const ImageViewer = (props) => {
  const [data, setData] = useState(null);
  const {
    component,
    dashboardComponent,
    dataQuery,
    query,
    dbcSearchParams,
    coreSearchParams,
    handleVisibilitySensorChange,
  } = props;

  const cmpOptions = component.component_options;
  const dbcOptions = dashboardComponent.dbc_component_options;
  const displayOptions = dashboardComponent.dbc_display_options;

  const options = {};

  const mergedOptions = _merge({}, options, cmpOptions, dbcOptions);

  const Controls = () => {
    const { zoomIn, zoomOut, resetTransform } = useControls();
    return (
      <>
        <button
          className="btn btn-light btn-xs waves-effect waves-light m-1"
          onClick={() => zoomIn()}
        >
          Zoom In
        </button>
        <button
          className="btn btn-light btn-xs waves-effect waves-light m-1"
          onClick={() => zoomOut()}
        >
          Zoom Out
        </button>
        <button
          className="btn btn-light btn-xs waves-effect waves-light m-1"
          onClick={() => resetTransform()}
        >
          Reset
        </button>
      </>
    );
  };

  useEffect(() => {
    if (dataQuery.isLoading) return;

    const data = TransformData({
      component,
      dashboardComponent,
      data: dataQuery?.data,
    });

    setData(data?.data);
  }, [dataQuery]);

  return (
    <VisibilitySensor
      onChange={handleVisibilitySensorChange}
      partialVisibility={true}
    >
      <div className={`card  mb-0 ${!isMobile ? "h-100" : "h-100"}`}>
        <div className="card-body">
          <Link
            to={`/dashboards/component/${dashboardComponent?.dbc_id}${
              coreSearchParams && Object.keys(coreSearchParams).length
                ? `?${new URLSearchParams(coreSearchParams).toString()}`
                : ""
            }`}
          >
            <h4 className="header-title">
              {mergedOptions.title ? `${mergedOptions.title}` : null}
            </h4>
          </Link>
          {mergedOptions.subtitle ? (
            <p className="card-subtitle mb-2 text-muted">
              {mergedOptions.subtitle}
            </p>
          ) : null}
          {dataQuery.isLoading ? (
            <div className="text-center">
              <Skeleton width={"25%"} />
            </div>
          ) : null}

          {data ? (
            <div style={{ textAlign: "center" }}>
              <TransformWrapper>
                <Controls />
                <TransformComponent>
                  <img
                    // src="https://images.unsplash.com/photo-1506744038136-46273834b3fb?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=870&q=80"
                    src={data?.image?.src}
                    alt="test"
                    width="100%"
                    height={`${
                      isMobile ? "auto" : `${displayOptions.h * 102 - 200}px`
                    }`}
                  />
                </TransformComponent>
              </TransformWrapper>             
            </div>
          ) : null}
        </div>
        <DbcFooter {...props} />
      </div>
    </VisibilitySensor>
  );
};

export default ImageViewer;
