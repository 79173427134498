import { head } from "lodash";
import DbcQueryParams from "./dbc-query-params";
import moment from "moment";
import { useContext } from "react";
import { UserContext } from "../contexts/user-context";

const DbcFooter = (props) => {
  const { dataQuery, data } = props;
  const {user} = useContext(UserContext);

  const refetch = () => {
    dataQuery.dataCache = false;
    dataQuery.refetch();
  };

  return (
    <div className="card-footer  bg-transparent pt-0">
      <div className="row">
        <div className="col-md-12">
          <div className="d-flex justify-content-center">
            {data ? <DbcQueryParams {...props} /> : null}
          </div>
        </div>
      </div>
      <div className="row">
        <div className="col-md-8">
          {/* <div className="float-end">
            {data ? <DbcQueryParams {...props} /> : null}
          </div> */}
        </div>
        <div className="col-md-4 align-self-end">
          <div className="float-end">
            {user &&
            (user.role === "super_admin" || user.role === "admin") &&
            !dataQuery.isLoading ? (
              <span
                className="m-1"
                onClick={refetch}
                style={{ cursor: "pointer" }}
              >
                <i className="fas fa-redo-alt"></i>
              </span>
            ) : null}

            {dataQuery.isLoading || dataQuery.isRefetching ? (
              <span className="text-warning m-1">Loading...</span>
            ) : (
              <span className="text-success m-1">
                {dataQuery.data && dataQuery.data.updated_at
                  ? moment.utc(dataQuery.data.updated_at).fromNow(true)
                  : "..."}{" "}
                <i className="fas fa-clock"></i>{" "}
              </span>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default DbcFooter;
