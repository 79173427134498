import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import config from "../../../config";

function DashboardSearchparams(props) {
  const { "*": slug } = useParams();
  const {
    dashboardQuery,
    dashboardSearchParams,
    setDashboardSearchParams,
    setSearchParams,
  } = props;
  const navigate = useNavigate();
  const [_dashboardSearchParams, set_DashboardSearchParams] = useState();
  const [customSearchData, setCustomSearchData] = useState({});

  useEffect(() => {
    set_DashboardSearchParams(dashboardSearchParams);
  }, [dashboardSearchParams]);

  const doSearch = async () => {
    let isValid = true;
    Object.keys(_dashboardSearchParams).forEach((param) => {
      _dashboardSearchParams[param] = _dashboardSearchParams[param].trim();
      if (
        _dashboardSearchParams[param] === "" ||
        _dashboardSearchParams[param].length === 0
      ) {
        isValid = false;
      }

      //Accept custom search data click search
      if(customSearchData[param] && customSearchData[param].map((record)=>record.key).includes(_dashboardSearchParams[param])){
        return;
      }


      if (customSearchData[param] && customSearchData[param].length > 1) {
        isValid = false;
      }

      if (customSearchData[param] && customSearchData[param].length === 1) {
        set_DashboardSearchParams((prevState) => ({
          ...prevState,
          [param]: customSearchData[param][0].key,
        }));
        _dashboardSearchParams[param] = customSearchData[param][0].key;
      }
    });

    if (!isValid) {
      return; // Stop further execution
    }

    if (Object.keys(_dashboardSearchParams).length) {
      localStorage.setItem(
        `dashboard:${slug}`,
        JSON.stringify(_dashboardSearchParams)
      );
    }

    const allSearchParams = new URLSearchParams(window.location.search);
    const mergedSearchParams = new URLSearchParams({
      ...Object.fromEntries(allSearchParams.entries()),
      ..._dashboardSearchParams,
    });

    navigate(`?${mergedSearchParams.toString()}`);
    //setDashboardSearchParams(_dashboardSearchParams);
    //setSearchParams(_dashboardSearchParams);
  };

  const doCustomSearch = async (searchParam, value) => {
    if (!searchParam.data_source) return;

    let method = "GET";

    let headers = {
      "x-data-cache": false,
    };

    let fetchOptions = {
      method: method,
      headers: headers,
      cache: "no-cache",
      credentials: "include",
    };

    let dataSource = searchParam.data_source;
    let url = `${config.api.url}/query/${dataSource.query_id}`;
    let queryParams = {};
    queryParams[dataSource.key] = value;
    const response = await fetch(
      `${url}${
        Object.keys(queryParams).length
          ? "?" +
            new URLSearchParams({
              ...queryParams,
            })
          : ""
      }`,
      fetchOptions
    );

    let responseData = await response.json();

    searchParam.custom_data = responseData?.data;
    setCustomSearchData((prevState) => ({
      ...prevState,
      [searchParam.key]: searchParam.custom_data,
    }));
  };

  return (
    <>
      <div
        id="dashboard-searchparams-modal"
        className="modal fade"
        tabIndex={-1}
        role="dialog"
        aria-hidden="true"
        style={{ display: "none" }}
      >
        <div className="modal-dialog">
          <div className="modal-content">
            <div className="modal-header">
              <h4 className="modal-title">Search Preferences</h4>
              <button
                type="button"
                className="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
              />
            </div>
            <div className="modal-body p-4">
              {dashboardQuery.data?.dashboard.dbd_options.search_params &&
                dashboardQuery.data?.dashboard.dbd_options.search_params.map(
                  (searchParam, index) => {
                    return (
                      <div className="row" key={index}>
                        <div className="col-md-12">
                          <div className="mb-3">
                            <label className="form-label">
                              {searchParam.label}
                            </label>
                            <input
                              type="text"
                              className="form-control"
                              placeholder={`${searchParam.placeholder}`}
                              value={
                                _dashboardSearchParams[searchParam.key] || ""
                              }
                              onChange={(e) => {
                                doCustomSearch(searchParam, e.target.value);
                                set_DashboardSearchParams((prevState) => ({
                                  ...prevState,
                                  [searchParam.key]: e.target.value,
                                }));
                              }}
                            />
                            {searchParam?.custom_data &&
                            searchParam?.custom_data.length > 0 ? (
                              <div className="mt-1">
                                <label className="form-text ">
                                  Matched Records
                                </label>
                                <br />
                                {searchParam?.custom_data?.map(
                                  (record, index) => {
                                    // Check if the record object and the desired key exist before accessing it

                                    // Return the value if it exists, or some placeholder if it doesn't
                                    return (
                                      <span
                                        key={index}
                                        class="form-text text-primary"
                                        style={{
                                          cursor: "pointer",
                                          display: "block",
                                        }}
                                        onClick={() => {
                                          set_DashboardSearchParams(
                                            (prevState) => ({
                                              ...prevState,
                                              [searchParam.key]: record["key"],
                                            })
                                          );
                                          setTimeout(() => {
                                            window
                                              .$(
                                                '#dashboard-searchparams-modal button[name="searchBtn"]'
                                              )
                                              .click();
                                          }, 500);
                                        }}
                                      >
                                        {record["key"]} ({record["value"]})
                                      </span>
                                    );
                                  }
                                )}
                              </div>
                            ) : null}
                          </div>

                          <div
                            class="form-text text-primary"
                            style={{ cursor: "pointer" }}
                            onClick={() => {
                              for (var searchParam of dashboardQuery.data
                                ?.dashboard.dbd_options.search_params) {
                                set_DashboardSearchParams((prevState) => ({
                                  ...prevState,
                                  [searchParam.key]: searchParam.value,
                                }));
                              }
                              //doSearch();
                              setTimeout(() => {
                                window
                                  .$(
                                    '#dashboard-searchparams-modal button[name="searchBtn"]'
                                  )
                                  .click();
                              }, 500);
                            }}
                          >
                            Or use sample values instead
                          </div>
                        </div>
                      </div>
                    );
                  }
                )}
            </div>
            <div className="modal-footer">
              <button
                type="button"
                className="btn btn-secondary waves-effect"
                data-bs-dismiss="modal"
              >
                Close
              </button>
              <button
                type="button"
                className="btn btn-info waves-effect waves-light"
                name="searchBtn"
                onClick={() => {
                  doSearch();
                }}
                // onClick={() => {
                //   //console.log(dashboardSearchParams);
                //   //Store Cache
                //   if (Object.keys(dashboardSearchParams).length) {
                //     localStorage.setItem(
                //       `dashboard:${slug}`,
                //       JSON.stringify(dashboardSearchParams)
                //     );
                //   }

                //   setSearchParams(dashboardSearchParams);
                // }}
              >
                View
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default DashboardSearchparams;
