import ApiService from "./api";
import moment from "moment";


export const AuthService = {
  login: async (email, password) => {
    const response = await ApiService.post("login", {
      email: email,
      password: password,
    });
    if (!response || !response.ok) {
        return;
      //throw new Error("Network response was not ok");
    }
   

    const data = await response.json();

    if (data.status === "ok") {
      localStorage.setItem("user", JSON.stringify(data.user));
    }

    return data;
  },
  getUser: async (token) => {
    const response = await ApiService.get("auth/get_user", {
      token: token
    });
    if (!response || !response.ok) {
      return;  
      //throw new Error("Network response was not ok");
    }

    const data = await response.json();

    if (data.status === "ok") {
      localStorage.setItem("user", JSON.stringify(data.user));
    }

    return data;
  },
  logout: async () => {
    const response = await ApiService.post("auth/logout", {});
    if (!response || !response.ok) {
      return;  
      //throw new Error("Network response was not ok");
    }

    const data = await response.json();

    if (data.status === "ok") {
      localStorage.removeItem("user");
    }

    return data;
  }, 
  me: async () => {
    const response = await ApiService.get("auth/me", {
      timestamp: moment.utc().valueOf(),
    });
    if (!response || !response.ok) {
      return;   
      //throw new Error("Network response was not ok");
    }

    const data = await response.json();

    if (data.status === "ok") {
      localStorage.setItem("user", JSON.stringify(data.user));
    }

    return data;
  },
};

export default AuthService;
