import { Outlet } from "react-router-dom";

const AuthLayout = () => {
  return (
    <>     
      
      <div id="wrapper">
      
        <div className="content-page">
          <div className="content">
            <div className="container-fluid">
              <Outlet />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default AuthLayout;
