import { Link, useLocation, useSearchParams } from "react-router-dom";
import { useQuery } from "@tanstack/react-query";
import config from "../../../app/config";
import moment from "moment";
import PageBreadcrumb from "../../layouts/components/page-breadcrumb";
import { BreadcrumbsItem } from "react-breadcrumbs-dynamic";
import DashboardSettings from "../../../app/components/modals/dashboard/dashboard-settings";
import { UserContext } from "../../../app/contexts/user-context";
import { useContext } from "react";
import Skeleton from "react-loading-skeleton";

const Dashboards = () => {
  const location = useLocation();
  const [searchParams, setSearchParams] = useSearchParams();
  const { user } = useContext(UserContext);

  const dashboardsQuery = useQuery({
    queryKey: ["dashboards", location],
    queryFn: async () => {
      try {
        const params = new URLSearchParams({});

        const pathname = location.pathname;
        const pathnameMatches = /^\/([A-Z]+)$/.exec(pathname);

        if (pathnameMatches && pathnameMatches[1])
          params.append("tags", pathnameMatches[1]);

        if (searchParams.get("tags"))
          params.append("tags", searchParams.get("tags"));

        const response = await fetch(
          `${config.api.url}/dashboards${
            params.size ? `?${params.toString()}` : ""
          }`
        );
        if (!response.ok) {
          throw new Error("Network response was not ok");
        }
        return response.json();
      } catch (err) {
        // Handle or log the error, if needed
        console.error("Error fetching:", err);
        return null;
      }
    },
  });

  return (
    <>
      <PageBreadcrumb title="Dashboards" />

      {user && user.role === "super_admin" ? (
        <div className="row">
          <div className="col-sm-12">
            <div className="float-sm-end">
              <form className="d-flex align-items-center flex-wrap">
                <button
                  type="button"
                  className="btn mb-2 ms-2 mb-sm-0 btn-sm btn-success"
                  data-bs-toggle="modal"
                  data-bs-target="#dashboard-settings-modal"
                >
                  Create Dasboard
                </button>
              </form>
            </div>
          </div>
        </div>
      ) : null}

      {dashboardsQuery.data?.tag ? (
        <div className="row mt-4">
          <div className="col-sm-12">
            <div className="card">
              <div className="card-body">
                <div className="d-flex align-items-start">
                  <div className="avatar-xxl me-3">
                    <div className="avatar-title  ">
                      <img
                        src={`/ui-assets/tags/${dashboardsQuery.data?.tag.tag_tag.toLowerCase()}.svg`}
                        alt={`${dashboardsQuery.data?.tag.tag_tag}`}
                        className="avatar-xxl"
                        style={{ borderRadius: "16px" }}
                      />
                    </div>
                  </div>

                  <div className="flex-1">
                    <h4 className="my-1">
                      {dashboardsQuery.data?.tag.tag_tag}
                    </h4>

                    <p>{dashboardsQuery.data?.tag.tag_description}</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      ) : null}

      <div className="row mt-4">
        <div className="col-12">
          <div className="card">
            <div className="card-body">
              {dashboardsQuery.isLoading ? <Skeleton /> : null}
              {dashboardsQuery.data?.dashboards.map((dashboard) => {
                return (
                  <div
                    className="d-flex align-items-start"
                    key={dashboard.dbd_id}
                  >
                    <div className="avatar-md me-3">
                      <div className="avatar-title bg-light ">
                        <Link to={`/dashboard/${dashboard.dbd_slug}`}>
                          <img
                            src={`${dashboard.dbd_options.logo}`}
                            alt={`${dashboard.dbd_name}`}
                            className="avatar-md "
                            style={{ borderRadius: "8px" }}
                          />
                        </Link>
                      </div>
                    </div>
                    <div className="flex-1">
                      <h4 className="my-1">
                        <Link to={`/dashboard/${dashboard.dbd_slug}`}>
                          {dashboard.dbd_name}
                        </Link>
                      </h4>
                      <div className="button-list">
                        {dashboard.dbd_options.tags.map((tag, index) => {
                          return (
                            <button
                              key={index}
                              type="button"
                              className="btn btn-light btn-xs waves-effect waves-light"
                            >
                              {/* <Link to={`/dashboards?tags=${tag}`}>{tag}</Link> */}
                              <Link to={`/${tag}`}>{tag}</Link>
                            </button>
                          );
                        })}
                      </div>
                      <div className="mt-2">
                        <p className="text-muted  mb-2">
                          Creator: {dashboard.dbd_options.creator || "N/A"}
                        </p>
                        {/* <p className="text-muted  mb-2">
                          Website:&nbsp;
                          {dashboard.dbd_options.website ? (
                            <a
                              href={dashboard.dbd_options.website}
                              target="_blank"
                              rel="noopener noreferrer"
                            >
                              {dashboard.dbd_options.website}
                            </a>
                          ) : (
                            "N/A"
                          )}
                        </p> */}

                        {/* <p className="text-muted mt-2">
                        Created {moment(dashboard.dbd_created_at).fromNow()}
                      </p> */}
                      </div>
                    </div>
                  </div>
                );
              })}

              {/* 
          <ul>
            {dashboardsQuery.data?.dashboards.map((dashboard) => {
              return (
                <li key={dashboard.dbd_id}>
                  <Link to={`/dashboard/${dashboard.dbd_slug}`}>
                    {dashboard.dbd_name}
                  </Link>
                </li>
              );
            })}
          
          </ul> */}
            </div>
          </div>
        </div>
      </div>

      <div className="row mt-4">
        <div className="col-12">
          <div className="card">
            <div className="card-body">
              Ready to showcase your project to the world?{" "}
              <Link to={`/projects/new`}>Click here</Link> to list your project
            </div>
          </div>
        </div>
      </div>

      <DashboardSettings />
    </>
  );
};

export default Dashboards;
