import config from "../config";
//import { unsetUser } from "app/state/session";


export const apiHeaders = () => {
  const user = JSON.parse(localStorage.getItem("user"));
  var headers = {};
  if (user) {
    //headers["x-access-token"] = user.access_token;
  }

  return headers;
};

// function useTest() {
//   let history = useHistory();
//   const dispatch = useDispatch();
//   dispatch(unsetUser());
//   history.push("/admin/login");
// }

export const apiErrors = (error) => {
  if (error.status === 401) {
    //if (ApiService.sessionLoggedIn) {
      //ApiService.dispatch(unsetUser());
    //}
  }
  else if (error.status === 500)
    //window.location.href = '/admin/error/500';

  return error;
};

export const apiResponse = (response) => {
  if (!response.ok) {
    apiErrors(response);
  }
  return response;
};

export const ApiService = {
  //url: `${config.api.url}`,
  // dispatch: null, //set from App.js
  // setDispatch: function (dispatch) {
  //   this.dispatch = dispatch;
  // },
  // sessionLoggedIn: null, //set from App.js
  // setSessionLoggedIn: function (sessionLoggedIn) {
  //   this.sessionLoggedIn = sessionLoggedIn;
  // },
  get: async (endpoint, params, headers) => {
    return fetch(
      `${config.api.url}/${endpoint}?` +
        new URLSearchParams({
          ...params,
        }),
      {
        method: "GET",
        credentials: 'include',      
        headers: {
          ...headers,
          ...apiHeaders(),
        }
      }
    )
      .then(apiResponse)
      .catch(apiErrors);

    // const response = await fetch(
    //   `${ApiService.url}/${endpoint}?` +
    //     new URLSearchParams({
    //       ...params,
    //       key: 123456,
    //     })
    // );
    // return await response.json();
  },

  post: (endpoint, data, headers) => {
    return fetch(`${config.api.url}/${endpoint}`, {
      method: "POST",
      credentials: 'include',      
      headers: {
        "Content-Type": "application/json",
        ...headers,
        ...apiHeaders(),
      },
      body: JSON.stringify(data),
    });
  },

  put: (endpoint, data, headers) => {
    return fetch(`${config.api.url}/${endpoint}`, {
      method: "PUT",
      credentials: 'include',  
      headers: {
        "Content-Type": "application/json",
        ...headers,
        ...apiHeaders(),
      },
      body: JSON.stringify(data),
    });
  },

  delete: (endpoint, data, headers) => {
    return fetch(`${config.api.url}/${endpoint}`, {
      method: "DELETE",
      credentials: 'include',  
      headers: {
        "Content-Type": "application/json",
        ...headers,
        ...apiHeaders(),
      },
      body: JSON.stringify(data),
    });
  },
};

export default ApiService;
