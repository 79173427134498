import Web3 from 'web3';
const web3 = new Web3();

const adjectives = [
  "Stellar",
  "Cosmic",
  "Galactic",
  "Lunar",
  "Solar",
  "Stellar",
  "Celestial",
  "Orbital",
  "Astral",
  "Starlit",
  "Meteoric",
  "Nebular",
  "Quantum",
  "Void",
  "Shining",
  "Eclipse",
  "Astric",
  "Supernovic",
  "Planetary",
  "Gravity",
  "Milky",
  "Photonic",
  "Dark",
  "Space",
  "Space",
  "Astro",
  "Nebulaic",
];

const nouns = [
  "Voyager",
  "Orion",
  "Andromeda",
  "Pulsar",
  "Quasar",
  "BlackHole",
  "Spacecraft",
  "Asteroid",
  "Galaxy",
  "Nebula",
  "Starship",
  "Meteorite",
  "Cosmonaut",
  "Astronaut",
  "Satellite",
  "Comet",
  "Planetoid",
  "Star",
  "Moon",
  "Sun",
  "Universe",
  "Wormhole",
  "Spacesuit",
  "Telescope",
  "Astrolab",
  "Rocket",
  "Mars",
  "Venus",
  "Mercury",
  "Jupiter",
];

function hashEntities(...args) {
  const values = args.reduce((prev, arg) => `${prev}${arg}`, "");
  return web3.utils.keccak256(values);
}


export const entityToPlayerName = (entity) => {
  if (!entity || entity == '0x0000000000000000000000000000000000000000') return "Nobody";

  const hash = web3.utils.keccak256(entity);

  const adjIndex = parseInt(hash.substring(0, 8), 16) % adjectives.length;
  const nounIndex = parseInt(hash.substring(8, 16), 16) % nouns.length;
  const number = parseInt(hash.substring(16, 20), 16) % 100;

  const name = `${adjectives[adjIndex]}.${nouns[nounIndex]}-${number}`;

  return name;
};

export const entityToRockName = (entity) => {
  const hash = web3.utils.keccak256(entity);

  const prefix1 = parseInt(hash.substring(0, 4), 16) % 26;
  const prefix2 = parseInt(hash.substring(4, 8), 16) % 26;
  const number = parseInt(hash.substring(8, 12), 16) % 251;
  const suffix = parseInt(hash.substring(12, 16), 16) % 26;

  const name = `${String.fromCharCode(65 + prefix1)}${String.fromCharCode(
    65 + prefix2
  )} ${number} ${String.fromCharCode(65 + suffix)}`;

  return name;
};

const phoneticAlphabet = {
  A: "Alpha",
  B: "Bravo",
  C: "Charlie",
  D: "Delta",
  E: "Echo",
  F: "Foxtrot",
  G: "Golf",
  H: "Hotel",
  I: "India",
  J: "Juliet",
  K: "Kilo",
  L: "Lima",
  M: "Mike",
  N: "Nova",
  O: "Oscar",
  P: "Papa",
  Q: "Quebec",
  R: "Romeo",
  S: "Sierra",
  T: "Tango",
  U: "Uniform",
  V: "Victor",
  W: "Whiskey",
  X: "Xray",
  Y: "Yankee",
  Z: "Zulu",
};

const getAlphabetLetter = (index) => "ABCDEFGHIJKLMNOPQRSTUVWXYZ"[index % 26];

const extendName = (name) => {
  return `${phoneticAlphabet[name[0]]} ${phoneticAlphabet[name[1]]} ${phoneticAlphabet[name[2]]}`;
};

export const entityToFleetName = (entity, shorten) => {
  const hash = web3.utils.keccak256(entity);
  const index1 = parseInt(hash.substring(0, 8), 16) % 26;
  const index2 = parseInt(hash.substring(8, 16), 16) % 26;
  let index3 = parseInt(hash.substring(16, 32), 16) % 26;
  let name = `${getAlphabetLetter(index1)}${getAlphabetLetter(index2)}${getAlphabetLetter(index3)}`;
//  while (fleetNameToEntity(name)) {
//    index3 = (index3 + 1) % 26;
//    name = `${getAlphabetLetter(index1)}${getAlphabetLetter(index2)}${getAlphabetLetter(index3)}`;
//  }
  return shorten ? name : extendName(name);
};

//export const fleetNameToEntity = (name) => {
//  return [...entityFleetName.entries()].find(([, v]) => v === name)?.[0];
//};

export const entityToTypeName = (entityType) => {
  if(!entityType) return "";
  
  const [type, entity] = entityType.split('_');
  
  if(type === 'ASTEROID')
      return 'ASTEROID: ' + entityToRockName(entity);
  
  if(type === 'FLEET')
      return 'FLEET: ' + entityToFleetName(entity);

  return "";
};
