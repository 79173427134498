import { useEffect, useState } from "react";
import { useSearchParams } from "react-router-dom";
import config from "../../../app/config";

const Spectate = () => {
  const [searchParams] = useSearchParams();
  const [data, setData] = useState(null);

  useEffect(() => {
	const initialize = async () => { 
		setData({ loading: true, iframe: false });
		const project = searchParams.get("project");
		let match = searchParams.get("match");
		const chainId = searchParams.get("chainId") || 690;
		let startBlock = searchParams.get("startBlock");
		const sessionId = Math.floor(Math.random() * 9000000) + 1000;

		if (!project || !match || !chainId) {
		  setData({ loading: false, error: true });
		  return;
		}
		
		//If match index then fetch the match entity
		if (!/^(0x)?[0-9a-zA-Z]{66}$/.test(match)) {
			await fetch(`${config.api.url}/query/491?index=${match}`, {
			method: "GET",
			// headers: {
			//   "x-data-cache": false,
			// },
			cache: "no-cache",
			credentials: "include",
		  }).then(async (response) => {
			  if (!response.ok) {
				throw new Error(`HTTP error! Status: ${response.status}`);
			  }
			  
			  const data = await response.json();
			  match = data?.data[0]?.key ?? '0x';
		  })
		    .catch((error) => {
			  setData({ loading: false, error: true });
			  return;
			});
		}

		if (!startBlock) {
		  fetch(`${config.api.url}/query/485?entity=${match}`, {
			method: "GET",
			// headers: {
			//   "x-data-cache": false,
			// },
			cache: "no-cache",
			credentials: "include",
		  })
			.then(async (response) => {
			  if (!response.ok) {
				throw new Error(`HTTP error! Status: ${response.status}`);
			  }

			  const data = await response.json();
			  startBlock = data?.data[0]?.min_value ?? 0;
			  const iframeUrl = `https://skystrife_${startBlock}_session${sessionId}.yeomen.ai/match?match=${match}&chainId=${chainId}`;
			  setData({ loading: false, iframe: iframeUrl });
			})
			.catch((error) => {
			  setData({ loading: false, error: true });
			});
		} else {
		  const iframeUrl = `https://skystrife_${startBlock}_session${sessionId}.yeomen.ai/match?match=${match}&chainId=${chainId}`;

		  setData({ loading: false, iframe: iframeUrl });
		}
	
	}
	
	initialize();
  }, []);

  return (
    <>
      {data && data.loading ? "Please wait...." : null}
      {data && data.error ? "Error loading...." : null}
      {data && data.iframe ? (
        <iframe src={data.iframe} style={{ width: "100%", height: "100vh" }} />
      ) : null}
    </>
  );
};

export default Spectate;
