import React, { useEffect, useState } from "react";
import _merge from "lodash/merge";
import TransformData from "../helpers/data-transformer";
import DbcQueryParams from "./dbc-query-params";
import { scaleLinear } from "@visx/scale";
import { HeatmapRect } from "@visx/heatmap";
import ParentSize from "@visx/responsive/lib/components/ParentSize";
import Skeleton from "react-loading-skeleton";
import DbcFooter from "./dbc-footer";
import { isMobile } from "react-device-detect";
import { Link } from "react-router-dom";
import VisibilitySensor from "react-visibility-sensor";

function RenderColormap({ data, width, height, query, dashboardComponent }) {
  const queryParams = query.qry_query_params || [];
  width = width - (!isMobile ? 200 : 50);
  height = height - (!isMobile ? 200 : 50); // - (queryParams.length ? 65 : 0)
  const events = true;
  const margin = { top: 0, left: 0, right: 0, bottom: 0 };
  const separation = 0;

  const binData = data.bins;

  function max(data, value) {
    return Math.max(...data.map(value));
  }

  // function min(data, value) {
  //   return Math.min(...data.map(value));
  // }

  // // accessors
  const bins = (d) => d.bins;
  // const count = (d) => d.count;

  // const colorMax = max(binData, (d) => max(bins(d), count));
  const bucketSizeMax = max(binData, (d) => bins(d).length);

  // // scales
  const xScale = scaleLinear({
    domain: [0, binData.length],
  });
  const yScale = scaleLinear({
    domain: [0, bucketSizeMax],
  });
  // const rectColorScale = scaleLinear({
  //   range: [hot1, hot2],
  //   domain: [0, colorMax],
  // });
  // const opacityScale = scaleLinear({
  //   range: [0.1, 1],
  //   domain: [0, colorMax],
  // });

  // bounds
  const size =
    width > margin.left + margin.right
      ? width - margin.left - margin.right - separation
      : width;

  const xMax = size;
  const yMax = height - 10;

  // const binWidth = xMax / binData.length;
  // const binHeight = yMax / bucketSizeMax;
  const binWidth = xMax / binData.length;
  const binHeight = yMax / bucketSizeMax;

  xScale.range([0, xMax]);
  yScale.range([yMax, 0]);

  useEffect(() => {
    console.log(
      `[data-bs-toggle="VisxColormap-tooltip${dashboardComponent.dbc_id}"]`
    );
    window.$.fn.tooltip &&
      window
        .$(
          `[data-bs-toggle="VisxColormap-tooltip${dashboardComponent.dbc_id}"]`
        )
        .tooltip();
  }, []);

  const getTooltipTitle = (bin) => {
    const { row, column } = bin;

    return `Cords: (${bin.bin.bin},${bin.datum.bin})<br/>Color: ${
      bin.bin.color || "N/A"
    }(${bin.bin.value || "N/A"})`;
    // return JSON.stringify({
    //   x: bin.bin.bin,
    //   y: bin.datum.bin,
    //   value: bin.bin.value,
    //   color: bin.bin.color,
    // });
  };

  return width < 10 ? null : (
    <div
      className="text-center"
      id={`VisxColormap-tooltip-container${dashboardComponent.dbc_id}`}
    >
      <svg width={width} height={height}>
        <HeatmapRect
          data={binData}
          xScale={(d) => xScale(d) ?? 0}
          yScale={(d) => yScale(d) ?? 0}
          //colorScale={rectColorScale}
          //opacityScale={opacityScale}
          binWidth={binWidth}
          binHeight={binHeight}
          gap={1}
        >
          {(heatmap) =>
            heatmap.map((heatmapBins) =>
              heatmapBins.map((bin) => (
                <rect
                  key={`heatmap-rect-${bin.row}-${bin.column}`}
                  className="visx-heatmap-rect"
                  width={bin.width}
                  height={bin.height}
                  x={bin.x}
                  y={bin.y}
                  fill={bin.bin.color || "#fff"}
                  //fillOpacity={bin.opacity}
                  onClick={() => {
                    //console.log(bin);
                    //if (!events) return;
                    //const { row, column } = bin;
                    //alert(JSON.stringify({ row, column, bin: bin.bin }));
                  }}
                  data-bs-container={`#VisxColormap-tooltip-container${dashboardComponent.dbc_id}`}
                  data-bs-toggle={`VisxColormap-tooltip${dashboardComponent.dbc_id}`}
                  data-bs-placement="top"
                  data-bs-html="true"
                  data-bs-trigger="hover click"
                  title={getTooltipTitle(bin)}
                />
              ))
            )
          }
        </HeatmapRect>
      </svg>
    </div>
  );
}

function VisxColormap(props) {
  const [dataColorMap, setDataColorMap] = useState(null);
  const {
    component,
    dashboardComponent,
    dataQuery,
    query,
    dbcSearchParams,
    prevDbcSearchParams,
    coreSearchParams,
    handleVisibilitySensorChange,
  } = props;

  const cmpOptions = component.component_options;
  const dbcOptions = dashboardComponent.dbc_component_options;
  //const dbcExtraOptions = dashboardComponent.dbc_component_extra_options;

  const options = {};

  const mergedOptions = _merge({}, options, cmpOptions, dbcOptions);

  const queryParams = query.qry_query_params || [];

  useEffect(() => {
    if (dataQuery.isLoading) return;
    //if(dataColorMap &&  JSON.stringify(prevDbcSearchParams) === JSON.stringify(dbcSearchParams) ) return;

    const data = TransformData({
      component,
      dashboardComponent,
      data: dataQuery?.data,
    });

    setDataColorMap(data);
  }, [dataQuery]);

  return (
    <ParentSize>
      {({ width, height }) => (
        <>
          <VisibilitySensor
            onChange={handleVisibilitySensorChange}
            partialVisibility={true}
          >
            <div className={`card  mb-0 ${!isMobile ? "h-100" : "h-100"}`}>
              <div className="card-body">
                <Link
                  to={`/dashboards/component/${dashboardComponent?.dbc_id}${
                    coreSearchParams && Object.keys(coreSearchParams).length
                      ? `?${new URLSearchParams(coreSearchParams).toString()}`
                      : ""
                  }`}
                >
                  <h4 className="header-title">
                    {mergedOptions.title ? `${mergedOptions.title}` : null}
                  </h4>
                </Link>
                {mergedOptions.subtitle ? (
                  <p className="card-subtitle mb-2 text-muted">
                    {mergedOptions.subtitle}
                  </p>
                ) : null}
                {dataColorMap ? (
                  <RenderColormap
                    width={Math.min(width, height)}
                    height={Math.min(width, height)}
                    data={dataColorMap}
                    {...props}
                  />
                ) : null}
                {dataQuery.isLoading && !dataColorMap ? (
                  <div className="text-center">
                    <Skeleton width={"25%"} />
                  </div>
                ) : null}
              </div>
              <DbcFooter {...props} data={dataColorMap} />
              {/* {queryParams.length ? (
              <div className="card-footer  bg-transparent">
                <DbcQueryParams {...props} />
              </div>
            ) : null} */}
            </div>
          </VisibilitySensor>
        </>
      )}
    </ParentSize>
  );
}

export default VisxColormap;
