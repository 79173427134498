import { forwardRef, useEffect, useImperativeHandle, useState } from "react";

const DashboardIndexer = forwardRef((props, ref) => {
  const { mainDashboardIndexer } = props;
  const [formData, setFormData] = useState({
    database_id: "",
    api_endpoint: "",
    api_provider: "",
  });

  const api_providers = [
    { api_provider: "etherscan", api_endpoint: "https://api.basescan.org" },
    {
      api_provider: "blockscout",
      api_endpoint: "https://17001-explorer-api.quarry.linfra.xyz",
    },
  ];

  useEffect(() => {
    if (!mainDashboardIndexer) return;

    setFormData((prevFormData) => ({
      ...prevFormData,
      database_id: mainDashboardIndexer.dbi_options.database_id,
      api_endpoint: mainDashboardIndexer.dbi_options.api_endpoint,
      api_provider: mainDashboardIndexer.dbi_options.api_provider,
    }));
  }, [mainDashboardIndexer]);

  useImperativeHandle(ref, () => ({
    getFormData: () => formData,
  }));

  return (
    <>
      <form className="form-horizontal" role="form">
        <div className="mb-2 row">
          <label className="col-md-2 col-form-label">Database Id</label>
          <div className="col-md-2">
            <input
              type="text"
              className="form-control"
              required
              value={formData.database_id}
              onChange={(e) => {
                setFormData((prevFormData) => ({
                  ...prevFormData,
                  database_id: e.target.value,
                }));
              }}
            />
          </div>
        </div>
        <div className="mb-2 row">
          <label className="col-md-2 col-form-label">Api Provider</label>
          <div className="col-md-2">
            <select
              className="form-control"
              value={formData.api_provider}
              onChange={(e) => {
                let api_provider = api_providers.find(
                  (api_provider) => api_provider.api_provider === e.target.value
                );
                setFormData((prevFormData) => ({
                  ...prevFormData,
                  api_provider: e.target.value,
                  api_endpoint: api_provider.api_endpoint,
                }));
              }}
            >
              <option value={''}>--Select--</option>
              {api_providers.map((api_provider, index) => (
                <option value={api_provider.api_provider} key={index}>
                  {api_provider.api_provider}
                </option>
              ))}
            </select>
          </div>
        </div>
        <div className="mb-2 row">
          <label className="col-md-2 col-form-label">Api Endpoint</label>
          <div className="col-md-6">
            <input
              type="text"
              className="form-control"
              readOnly
              value={formData.api_endpoint}
            />
          </div>
        </div>
      </form>
    </>
  );
});

export default DashboardIndexer;
