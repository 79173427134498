import _merge from "lodash/merge";
import TransformData from "../helpers/data-transformer";
import Markdown from "react-markdown";

function Text(props) {
  const { component, dashboardComponent } = props;

  const cmpOptions = component.cmp_options;
  const dbcOptions = dashboardComponent.dbc_component_options;
  //const dbcExtraOptions = dashboardComponent.dbc_component_extra_options;

  const markdown = dbcOptions.content;

  const options = {};

  const mergedOptions = _merge({}, options, cmpOptions, dbcOptions);

  //const data = TransformData({ component,dashboardComponent });

  return (
    <>
      <div className="card h-100 mb-0">
        <div className="card-body">
          <Markdown>{markdown}</Markdown>
        </div>
      </div>
    </>
  );
}

export default Text;
